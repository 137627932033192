import ccmClient from './ccmClient';
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getBadgingReport = async () => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.get('/export/plate-report', { headers });

    return data;
};

export default getBadgingReport;