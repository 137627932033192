import axios from 'axios';

const fetchToken = async (code, codeVerifier) => {

    const cwsUrl = `${process.env.REACT_APP_CWS_URL}/as/token.oauth2`;

    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('code', code);
    body.set('redirect_uri', process.env.REACT_APP_CWS_REDIRECT_URL);
    body.set('client_id', process.env.REACT_APP_CWS_CLIENTID);
    body.set('code_verifier', codeVerifier);

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${process.env.REACT_APP_CWS_SECRET}`
        }
    };

    const result = await axios.post(cwsUrl, body, config);

    return result;
};

export default fetchToken;