import { Component } from 'react';

import './FaqPage.css';

const FaqPage = (props) => {

    return (
        <>
            <div className="container container-search-results">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h2 style={{ marginTop: "7px", marginLeft: "10%", fontWeight: "bold" }}>FAQ</h2>
                            </div>
                            <div className="card-body">

                                <div style={{ width: "80%", margin: "0 auto" }}>
                                    <h4>Lorem ipsum dolor sit amet</h4>

                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed urna id ante pulvinar luctus vel id tellus. Etiam nibh enim, semper ac tristique ac, scelerisque vel lacus. Praesent commodo lectus vitae tellus scelerisque consequat. Proin elementum nisl porta, condimentum lectus eu, feugiat lectus. Donec augue sem, ornare et nibh vel, efficitur euismod libero. Etiam mattis faucibus lacinia. Nullam sed molestie diam.
                                    </p>

                                    <h4>Cras maximus ligula nibh</h4>

                                    <p>
                                        Cras maximus ligula nibh, quis dapibus magna lacinia dictum. Ut sed orci leo. Sed ultricies nisi non dictum lobortis. Phasellus ultricies arcu eget arcu tincidunt tristique. Praesent vel scelerisque nunc. In sed bibendum metus. Sed vel eros consequat, consectetur quam nec, egestas massa. Nunc vitae cursus dolor, et facilisis lorem. Aenean vitae semper quam. Vivamus porta sapien a mollis vehicula. Donec in metus mauris. Donec tempus lectus consectetur, scelerisque sem id, facilisis justo. Sed molestie purus felis.
                                    </p>

                                    <h4>Donec posuere nibh non cursus consectetur</h4>

                                    <p>
                                        Donec posuere nibh non cursus consectetur. Cras ac sagittis enim. Aliquam quis leo a orci accumsan viverra. Phasellus aliquet iaculis leo, ut faucibus ex faucibus eu. Fusce et aliquet dui. Aenean at sodales magna, a sagittis sem. Praesent lobortis dolor purus, sit amet gravida risus pharetra et.
                                    </p>

                                    <h4>Mauris sollicitudin pellentesque tempus</h4>

                                    <p>
                                        Mauris sollicitudin pellentesque tempus. Quisque iaculis nunc magna, in semper dui elementum ut. Quisque varius quis est nec bibendum. Nunc quis urna dignissim libero feugiat maximus id eget libero. Aenean ullamcorper lectus sit amet sapien sollicitudin, non sagittis urna finibus. Maecenas eget hendrerit libero, sit amet vulputate libero. Pellentesque ut est pretium, tristique massa eu, ultricies metus.
                                    </p>

                                    <h4>Cras maximus ligula nibh</h4>

                                    <p>
                                        Cras maximus ligula nibh, quis dapibus magna lacinia dictum. Ut sed orci leo. Sed ultricies nisi non dictum lobortis. Phasellus ultricies arcu eget arcu tincidunt tristique. Praesent vel scelerisque nunc. In sed bibendum metus. Sed vel eros consequat, consectetur quam nec, egestas massa. Nunc vitae cursus dolor, et facilisis lorem. Aenean vitae semper quam. Vivamus porta sapien a mollis vehicula. Donec in metus mauris. Donec tempus lectus consectetur, scelerisque sem id, facilisis justo. Sed molestie purus felis.
                                    </p>

                                    <h4>Donec posuere nibh non cursus consectetur</h4>

                                    <p>
                                        Donec posuere nibh non cursus consectetur. Cras ac sagittis enim. Aliquam quis leo a orci accumsan viverra. Phasellus aliquet iaculis leo, ut faucibus ex faucibus eu. Fusce et aliquet dui. Aenean at sodales magna, a sagittis sem. Praesent lobortis dolor purus, sit amet gravida risus pharetra et.
                                    </p>

                                    <h4>Mauris sollicitudin pellentesque tempus</h4>

                                    <p>
                                        Mauris sollicitudin pellentesque tempus. Quisque iaculis nunc magna, in semper dui elementum ut. Quisque varius quis est nec bibendum. Nunc quis urna dignissim libero feugiat maximus id eget libero. Aenean ullamcorper lectus sit amet sapien sollicitudin, non sagittis urna finibus. Maecenas eget hendrerit libero, sit amet vulputate libero. Pellentesque ut est pretium, tristique massa eu, ultricies metus.
                                    </p>

                                    <h4>Cras maximus ligula nibh</h4>

                                    <p>
                                        Cras maximus ligula nibh, quis dapibus magna lacinia dictum. Ut sed orci leo. Sed ultricies nisi non dictum lobortis. Phasellus ultricies arcu eget arcu tincidunt tristique. Praesent vel scelerisque nunc. In sed bibendum metus. Sed vel eros consequat, consectetur quam nec, egestas massa. Nunc vitae cursus dolor, et facilisis lorem. Aenean vitae semper quam. Vivamus porta sapien a mollis vehicula. Donec in metus mauris. Donec tempus lectus consectetur, scelerisque sem id, facilisis justo. Sed molestie purus felis.
                                    </p>

                                    <h4>Donec posuere nibh non cursus consectetur</h4>

                                    <p>
                                        Donec posuere nibh non cursus consectetur. Cras ac sagittis enim. Aliquam quis leo a orci accumsan viverra. Phasellus aliquet iaculis leo, ut faucibus ex faucibus eu. Fusce et aliquet dui. Aenean at sodales magna, a sagittis sem. Praesent lobortis dolor purus, sit amet gravida risus pharetra et.
                                    </p>

                                    <h4>Mauris sollicitudin pellentesque tempus</h4>

                                    <p>
                                        Mauris sollicitudin pellentesque tempus. Quisque iaculis nunc magna, in semper dui elementum ut. Quisque varius quis est nec bibendum. Nunc quis urna dignissim libero feugiat maximus id eget libero. Aenean ullamcorper lectus sit amet sapien sollicitudin, non sagittis urna finibus. Maecenas eget hendrerit libero, sit amet vulputate libero. Pellentesque ut est pretium, tristique massa eu, ultricies metus.
                                    </p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqPage;