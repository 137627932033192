import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const archiveList = async (listId) => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.delete(`/lists/${listId}`, { headers });

    return data;
};

export default archiveList;