import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';

import CertificatePDF from './CertificatePDF';

const PDFViewerPage = () => (
  <PDFViewer style={{ width: "100%", height: "1000px", border: "0", margin: "0 auto", }} >
    <CertificatePDF />
  </PDFViewer>
);

export default PDFViewerPage;