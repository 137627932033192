import { useSelector, useDispatch } from 'react-redux';

import { Modal, Button } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Formik } from "formik";

import { setOpen as setNoteModalOpen, setEdit, setDelete, addNote, deleteNote } from '../notes/note.slice';
import noteSchema from './note.schema';

import './NotesModal.css'

const useStyles = makeStyles((theme) => ({
    input: {
        '&:after': {
            borderBottom: "2px solid #FFCC11",
        }
      },
}));

const NotesModal = () => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { 
        isOpen, 
        note, 
        isEdit, 
        isDelete
    } = useSelector(state => state.note);
    
    const handleEdit = () => dispatch(setEdit(true));
    const handleClose = () => dispatch(setNoteModalOpen(false));
    const handleCancelEdit = () => dispatch(setNoteModalOpen(false));
    const saveNote = (note, machineSerialNumber, serviceInterval) => dispatch(addNote({ note, machineSerialNumber, serviceInterval }));
    const handleShowDelete = () => dispatch(setDelete(true));
    const handleCancelDelete = () => dispatch(setDelete(false));
    const handleDelete = () => dispatch(deleteNote(note.id));

    let body = (
        <>
            <Modal.Body>
                <p className="modal-content-text">{note.value}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary modal-button" onClick={handleShowDelete}>
                    Delete
                </Button>
                <Button variant="primary" onClick={handleEdit}>
                    Edit
                </Button>
            </Modal.Footer>
        </>
    );

    if(isEdit){
        body = (
                
            <Formik
                initialValues={note}
                validationSchema={noteSchema}
                onSubmit={(values) => saveNote(values.value, values.machineSerialNumber, values.serviceInterval)}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (

                    <form onSubmit={handleSubmit} noValidate autoComplete="off">

                        <Modal.Body>

                            <TextField
                                id="value"
                                name="value"
                                label=""
                                multiline
                                rows={8}
                                variant="filled"
                                defaultValue={values.value}
                                className={`${classes.input} notes-textarea`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperText={touched.value && errors.value}
                                error={Boolean(touched.value && errors.value)}
                            />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" variant="secondary modal-button" disabled={isSubmitting} onClick={handleCancelEdit}>
                                Cancel
                            </Button>
                            <Button  type="submit" variant="primary" disabled={isSubmitting}>
                                Save
                            </Button>
                        </Modal.Footer>

                    </form>

                )}

            </Formik>
        );
    }

    let modalContent = (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Note</Modal.Title>
            </Modal.Header>
            
            {body}
        </>
    );

    if(isDelete){
        modalContent = (
            <> 
                <div className="container delete-container">
                    <div className="row delete-row">
                        <div className="col-sm-5"></div>
                        <div className="col-sm-2">
                            <span className="warning-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill warning-icon-delete" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                                </svg>&nbsp;
                            </span>
                        </div>
                        <div className="col-sm-5"></div>
                    </div>
                    <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10 delete-container-content">
                            <h5 className="delete-message">Are you sure you want to delete this note?</h5>
                            <h6 className="delete-warning">Once deleted this note can't be recovered.</h6>
                        </div>
                        <div className="col-sm-1"></div>
                    </div>
                    <div className="row delete-buttons">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-2">
                            <Button variant="secondary modal-button" onClick={handleCancelDelete}>
                                Cancel
                            </Button>
                        </div>
                        <div className="col-sm-1"></div>
                        <div className="col-sm-2">
                            <Button variant="primary" onClick={handleDelete}>
                                Delete
                            </Button>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered>
            
            {modalContent}
            
        </Modal>
    );
};

export default NotesModal;