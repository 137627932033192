import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import getNoteApi from '../../api/getNote';
import addNoteApi from '../../api/addNote';
import deleteNoteApi from '../../api/deleteNote';

import { setLoading } from '../loader/loader.slice';
import { getMachineDetails } from './../search-results/searchresults.slice'

const initialState = {
    isEdit: false,
    isDelete: false,
    isOpen: false,
    note: {
      id: 0,
      value: '',
      machineSerialNumber: '',
      serviceInterval: 0,
    }
};

export const addNote = createAsyncThunk('note/addNote', async ({note, serviceInterval, machineSerialNumber}, {dispatch}) => {

  dispatch(setLoading(true));

  const response = await addNoteApi(note, serviceInterval, machineSerialNumber);
  dispatch(getMachineDetails(machineSerialNumber));

  dispatch(setLoading(false));

  return response;
});

export const deleteNote = createAsyncThunk('note/deleteNote', async (id, {dispatch, getState}) => {

  dispatch(setLoading(true));

  const response = await deleteNoteApi(id);

  const { machineSerialNumber } = getState().note.note;
  dispatch(getMachineDetails(machineSerialNumber));

  dispatch(setLoading(false));

  return response;
});

export const openNote = createAsyncThunk('note/openNote', async (id, {dispatch}) => {

  dispatch(setLoading(true));

  const response = await getNoteApi(id);

  dispatch(setLoading(false));

  return response;
});

const noteFormSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {

    setEdit(state, action) {
      state.isEdit = action.payload;
    },

    setDelete(state, action) {
      state.isDelete = action.payload;
    },

    setOpen(state, action) {
      state.isOpen = action.payload;
    },

    openNewNote(state, action) {
      state.note = initialState.note;
      state.note.machineSerialNumber = action.payload.serialNumber;
      state.note.serviceInterval = action.payload.serviceInterval;
      state.isEdit = true;
      state.isOpen = true;
    },

  },
  extraReducers: builder => {
    builder
    
      .addCase(addNote.fulfilled, (state, action) => {
        state.note = initialState.note;
        state.isEdit = false;
        state.isOpen = false;
      })

      .addCase(deleteNote.fulfilled, (state, action) => {
        state.note = initialState.note;
        state.isDelete = false;
        state.isOpen = false;
      })
      
      .addCase(openNote.fulfilled, (state, action) => {
        state.note = action.payload.note;
        state.isEdit = false;
        state.isOpen = true;
      });
  }
});

export const { setEdit, setDelete, setOpen, openNewNote } = noteFormSlice.actions;

export default noteFormSlice.reducer;