import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { PDFViewer } from '@react-pdf/renderer';

import './CertificationSuccessModal.css';

import { setReportModalOpen } from './searchresults.slice';

import MachineHistoryPDF from '../export/MachineHistoryPDF';

const ReportModal = () => {

    const dispatch = useDispatch();

    const { isOpen } = useSelector(state => state.searchresults.reportModal);
    const { serialNumber, maintenanceHistory } = useSelector(state => state.searchresults);

    const handleClose = () => dispatch(setReportModalOpen(false));
    
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="xl"
        >
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body>
               
                <PDFViewer style={{ width: "100%", height: "1200px", border: "0", margin: "0 auto" }}>
                    <MachineHistoryPDF
                        maintenanceHistory={maintenanceHistory}
                        serialNumber={serialNumber}
                    />
                </PDFViewer>

            </Modal.Body>
        </Modal>
    );
};

export default ReportModal;