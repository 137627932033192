import ccmClient from './ccmClient';
import getCWSService from '../app/getCWSService';

const { getIdToken } = getCWSService();

const addWorkOrder = async (intervalId, serviceInterval, machineSerialNumber, number, serviceDate, machineHours, note) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/maintenance-history/work-orders/add', {
        workOrder: {
            intervalId: intervalId,
            machineSerialNumber: machineSerialNumber,
            number: number,
            serviceDate: serviceDate,
            machineHours: machineHours,
            note: note,
        },
        ServiceInterval: serviceInterval
    }, { headers });

    return data;
};

export default addWorkOrder;