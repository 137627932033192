import axios from 'axios';
import randomstring from "randomstring";
import { encode as base64encode } from "base64-arraybuffer";

const generateCodeChallenge = async (codeVerifier) => {

    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest("SHA-256", data);
    const base64Digest = base64encode(digest);

    return base64Digest
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
};

const authenticate = async () => {
    const code_verifier = randomstring.generate(128);            
    const code_challenge = await generateCodeChallenge(code_verifier);

    localStorage.setItem("state", JSON.stringify({
        code_verifier,
        code_challenge
    }));

    const authUrl = `${process.env.REACT_APP_CWS_URL}/as/authorization.oauth2?client_id=${process.env.REACT_APP_CWS_CLIENTID}&pfidpadapterid=${process.env.REACT_APP_CWS_ADAPTER}&response_type=code&grant_type=authorization_code&scope=openid+profile&state=${code_verifier}&code_challenge=${code_challenge}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_CWS_REDIRECT_URL)}`;
    window.location = authUrl;
};

const ccmClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        'x-csrf-token': '5265ed32-a388-11eb-bcbc-0242ac130002'
    }
});

ccmClient.interceptors.response.use(response => {
    return response;
 }, error => {
   if (error.response.status === 401) {
    localStorage.removeItem('token');
    authenticate();
   }
   return error;
 });

export default ccmClient;