import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ReportIssueModal from './features/report-issue/ReportIssueModal';

import './Footer.css'

const Footer = (props) => {

    let [ isOpen, setIsOpen ] = useState(false);

    return (
        <footer>
            <div className="row footer-row">

                <div className="col-sm-9 footer-links-container">
                    <Link to='/' className="footer-link">Home</Link>
                    <span className="footer-link-divider">|</span>
                    <Link to='/lists' className="footer-link">My Lists</Link>
                    <span className="footer-link-divider">|</span>
                    <a className="footer-link" onClick={() => setIsOpen(true)}>Report an Issue</a>
                    <span className="footer-link-divider">|</span>
                    <Link to="/faq" className="footer-link">FAQ</Link>
                </div>

                <div className="col-sm-3 copyright-container">
                    <p>Caterpillar &copy; {new Date().getFullYear()}. All rights reserved.</p>
                </div>

            </div>

            <ReportIssueModal
                isOpen={isOpen}
                handleClose={() => setIsOpen(false)}
                ></ReportIssueModal>

        </footer>
    );
};

export default Footer;