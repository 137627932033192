import ccmClient from './ccmClient';
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getMasterListReport = async (listId) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.get(`/master-lists/${listId}/report`, { headers });

    return data;
};

export default getMasterListReport;