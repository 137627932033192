import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'col',
    backgroundColor: '#FFFFFF',
    color: "#43485C"
  },
  headerSection: {
    padding: 20,
    flexGrow: 1,
    borderBottom: "1px solid #E6E9F0",
    height: 1,
    textAlign: "center",
    maxHeight: 60
  },
  tableRowSection: {
    padding: 20,
    flexGrow: 1,
    borderBottom: "1px solid #E6E9F0",
    height: 1,
    maxHeight: 60,
    width: "90%",
    marginLeft: 40,
  },
  tableRowHeader: { 
      flexDirection: "row", 
      fontSize: "14px", 
      textAlign: "left", 
      fontWeight: 800, 
      marginTop: 13 
  },
  tableRowBody: { 
      flexDirection: "row", 
      fontSize: "12px", 
      textAlign: "left", 
      fontWeight: 100, 
      marginTop: 3 
  },
  tableCell: {
      width: "20%"
  }
});

const MachineHistoryPDF = (props) => {

    const rows = props.maintenanceHistory.map(x => {

        const datePerformed = new Date(x.datePerformed);
        
        return (
            <View style={styles.tableRowSection}>

                <View style={styles.tableRowBody}>
                    <Text style={styles.tableCell}>{x.serviceInterval} ({x.description})</Text>
                    <Text style={styles.tableCell}>{x.machineHours}</Text>
                    <Text style={styles.tableCell}>{x.workOrderNumber}</Text>
                    <Text style={styles.tableCell}>{!!x.datePerformed ? format(datePerformed, 'MM/dd/yyyy') : ''}</Text>
                    <Text style={styles.tableCell}>{!!x.note && x.note.length > 100 ? `${x.note.substring(0, 100)}...` : x.note}</Text>
                </View>

            </View>
        )
    });

    return (
        <Document
            title={`Maintenance History ${props.serialNumber}`}
            author={`CAT CCM`}
        >
            <Page size="LETTER" orientation="landscape" style={styles.page}>
            <View style={styles.headerSection}>
                <Text>Maintenance History for {props.serialNumber}</Text>
            </View>
            <View style={styles.tableRowSection}>
                <View style={styles.tableRowHeader}>
                    <Text style={styles.tableCell}>PM Interval</Text>
                    <Text style={styles.tableCell}>Machine Hours</Text>
                    <Text style={styles.tableCell}>Work Order #</Text>
                    <Text style={styles.tableCell}>Date</Text>
                    <Text style={styles.tableCell}>Notes</Text>
                </View>
                
            </View>

            {rows}

            </Page>
        </Document>
    );
};

export default MachineHistoryPDF;