import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import { Formik } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import UploadButton from '@material-ui/core/Button';

import './AddListModal.css';

import { addList, setAddListModalOpen, updateList } from './list.slice';
import listSchema from './list.schema';
import UserList from './UserList';
import { useEffect, useState } from 'react';

import excelTemplate from '../../resources/template/Template.xlsx';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
    reader.onerror = error => reject(error);
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
        marginLeft: 0
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const AddListModal = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const { isAddListModalOpen, list } = useSelector(state => state.lists);

    const [ user, setUser ] = useState("");
    const [ users, setUsers ] = useState([]);
    const [ file, setFile ] = useState({ name: "", data: null });

    useEffect(() => {
        if(!!list && !!list.users){
            setUsers(list.users.split(','));
        }
        setFile({ name: "", data: null });
    }, [list]);
 
    const handleClose = () => {
        dispatch(setAddListModalOpen(false));
    };

    const isSubmitting = false;

    const handleUserChange = (e) => {
        
        let userId = e.target.value;

        if(userId.indexOf(" ") > -1 && userId.length > 1){
            setUsers([...users, userId.trim()]);
            setUser("");
        }
        else {
            userId = userId.trim();
            setUser(userId);
        }        
    };

    const handleOnBlur = (e) => {
        console.log(e.target.value);
        if(!!e.target.value){
            e.target.value += " ";
            handleUserChange(e);
        }
    };

    const handleDeleteUser = (user) => {
        const userIndex = users.indexOf(user);
        const newUsers = [...users];
        newUsers.splice(userIndex, 1);
        setUsers(newUsers);
    };

    const handleSubmitRequest = (listName) => {
        if(list.id > 0){
            dispatch(updateList({ listId: list.id, listName, users}));
        }
        else{
            dispatch(addList({ listName: listName, users: users, base64File: file.data }));
        }
        setUsers([]);
    };

    const handleFileUploaded = async (e) => {

        const addedFile = e.target.files[0];

        if(!addedFile) return;

        setFile({
            name: addedFile.name,
            data: await toBase64(addedFile)
        });

        e.target.value = null;
    };
    
    return (
        <Modal
            show={isAddListModalOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title style={{ marginLeft: '15px', marginTop: "10px"}}>
                    {list.id > 0 ? (<>Edit List</>) : (<>Add New List</>)}
                </Modal.Title>
            </Modal.Header>

            <div className="container">

                <Formik
                    initialValues={list}
                    validationSchema={listSchema}
                    onSubmit={ (values) => handleSubmitRequest(values.name) }
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (

                        <form onSubmit={handleSubmit} noValidate autoComplete="off">

                            <Modal.Body>

                                <div className="row">
                                    <div className="col-sm-12">

                                        <FormControl className={classes.formControl}>
                                            <TextField 
                                                id="name" 
                                                name="name" 
                                                label="List Name" 
                                                className={`${classes.input}`}
                                                defaultValue={values.name} 
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                helperText={touched.name && errors.name}
                                                error={Boolean(touched.name && errors.name)}
                                            />
                                        </FormControl>

                                    </div>
                                </div>

                                <div className="row mt-10">
                                    <div className="col-sm-12">

                                        <FormControl className={classes.formControl}>
                                            <TextField
                                                id="users"
                                                label="Users (CWS ID)"
                                                name="users"
                                                className={`${classes.input}`}
                                                value={user}
                                                onChange={ (e) => handleUserChange(e) }
                                                onBlur={(e) => { handleBlur(e); handleOnBlur(e); }}
                                                helperText={touched.users && errors.users}
                                                error={Boolean(touched.users && errors.users)}
                                            />
                                        </FormControl>

                                        <UserList users={users} deleteUser={handleDeleteUser}></UserList>

                                    </div>
                                </div>

                                {list.id> 0 ? (
                                    null
                                ):(
                                <div className="row mt-20">
                                    <div className="col-sm-12">

                                        <input
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            className={classes.input}
                                            id="raised-button-file"
                                            type="file"
                                            onChange={handleFileUploaded}
                                            hidden
                                            disabled={!!file.name}
                                        />

                                        <label htmlFor="raised-button-file">
                                            Serial Numbers <br /> 
                                            {!!file.name ? (<p className="file-name">File: {file.name}</p>) : null}
                                            <UploadButton variant="raised" component="div" className="upload-button" disabled={!!file.name}>
                                                Upload File
                                            </UploadButton>
                                        </label> 

                                    </div>

                                    <div className="col-sm-12">
                                        <p style={{ color: "#7B7B7B", fontSize: "13px", fontWeight: "bold" }}>The document should be an Excel file (2007 and later) with a single "Serial Number" column. Click <a href={excelTemplate}>here</a> to download a template.</p>
                                        <p style={{ color: "#7B7B7B", fontSize: "13px", fontWeight: "bold" }}>Lists are limited to 250 serial numbers.</p>
                                        <p style={{ color: "#7B7B7B", fontSize: "13px", fontWeight: "bold" }}>Please check these are valid serial numbers before running.</p>
                                    </div>
                                </div>
                                )}
                                

                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="button" variant="secondary modal-button" onClick={handleClose} disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="primary" disabled={isSubmitting}>
                                Save
                                </Button>
                            </Modal.Footer>

                        </form>

                    )}

                </Formik>

            </div>

        </Modal>
        
    );
};

export default AddListModal;