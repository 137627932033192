import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';

import store from './app/store';
import Header from './Header';
import './App.css';

import Loader from './features/loader/Loader';
import SearchPage from './features/search/SearchPage';
import SearchResultsPage from './features/search-results/SearchResultsPage';
import Authorization from './components/Authorization';
import PDFViewerPage from './features/export/PDFViewerPage';
import CertificateReportView from './features/certification-report/CertificationReportView';
import ListViewPage from './features/lists/ListViewPage';
import ListItemViewPage from './features/lists/ListItemViewPage';
import Footer from './Footer';
import FaqPage from './features/faq/FaqPage';
import MasterListViewPage from './features/master-lists/MasterListViewPage';
import MasterListItemViewPage from './features/master-lists/MasterListItemView';

function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
      
        <Loader></Loader>
        <Authorization>
          
            <Router>
              <Header></Header>
              <Route exact path="/" component={SearchPage}></Route>
              <Route exact path="/lists" component={ListViewPage}></Route>
              <Route exact path="/lists/:listId" component={ListItemViewPage}></Route>
              <Route exact path="/master-lists" component={MasterListViewPage}></Route>
              <Route exact path="/master-lists/:listId" component={MasterListItemViewPage}></Route>
              <Route path="/searchresults/:sn" component={SearchResultsPage}></Route>
              <Route path="/certificatereport/:sn" component={CertificateReportView}></Route>
              <Route path="/pdfviewer" component={PDFViewerPage}></Route>
              <Route path="/faq" component={FaqPage}></Route>
              <Footer></Footer>
            </Router>

        </Authorization>

      </Provider>
    </React.StrictMode>
  );
}

export default App;
