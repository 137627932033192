import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './CertifyModal.css';
import { runCertification, setCertifyModalOpen } from "./masterlistitem.slice";


const CertifyModal = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { certifyModal, list  } = useSelector(state => state.masterListItems);

    const handleClose = () => {
        dispatch(setCertifyModalOpen({ isOpen: false, listItem: {} }));
    };

    const handleDetails = () => {
        dispatch(setCertifyModalOpen({ isOpen: false, listItem: {} }));
        setTimeout(() => {
            history.push(`/searchresults/${certifyModal.listItem.serialNumber}`);
        }, 300);
    };

    const handleCertify = async () => {
        await dispatch(runCertification({ machines: [{ serialNumber: certifyModal.listItem.serialNumber, smu: certifyModal.listItem.highestEligibleQualification }]}));
        dispatch(setCertifyModalOpen({ isOpen: false, listItem: {} }));
        setTimeout(() => {
            history.push(`/searchresults/${certifyModal.listItem.serialNumber}`);
        }, 800);
    };
    
    return (
        <Modal
            show={certifyModal.isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                
                <p className="certify-modal-header">Certify</p>

                <p className="certify-modal-message mt-10">Ready to certify at {certifyModal.listItem.highestEligibleQualification} SMU.</p>
                
                <Button variant="primary modal-button mt-10" onClick={handleCertify}>
                    Certify
                </Button>

                <div className="row mt-30">
                    <div style={{ width: "50px" }}></div>
                    <div className="col-sm-5"><hr /></div>
                    <span className="certify-modal-message">OR</span>
                    <div className="col-sm-5"><hr /></div>
                </div>

                <p className="certify-modal-message mt-20">Certify at a different SMU hour.</p>
                
                <Button variant="secondary modal-button mt-20 mb-30" onClick={handleDetails}>
                    View Details
                </Button>

            </Modal.Body>
        </Modal>
    );
};

export default CertifyModal;