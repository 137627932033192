import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import CircularProgressWithLabel from './CirtcularProgressWithLabel';
import './ListViewPage.css';

import { addNewList, archiveList, loadLists, loadlistsquietly, runList, setAddListModalOpen } from './list.slice';
import MoreButton from './MoreButton';
import AddListModal from './AddListModal';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import getCWSService from '../../app/getCWSService';

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = () => {
            savedCallback.current();
        }
        if(delay === null){
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
};

const ListViewPage = (props) => {

    const dispatch = useDispatch();

    const { 
        lists,
        isAddListModalOpen
    } = useSelector(state => state.lists);

    const [ isAdmin, setIsAdmin ] = useState(false);

    useEffect(async () => {

        const { getUserProfile } = getCWSService();
        const profile = await getUserProfile();
        console.log(profile);
        if(!!profile){
            
            const isUserAdmin = profile["http://schemas.cat.com/identity/claims/uniquememberof"].indexOf("cn=ci-ccm-superadmin,ou=Groups,DC=cat,DC=com") > -1;
            const isSuperAdmin = profile["http://schemas.cat.com/identity/claims/uniquememberof"].indexOf("cn=ci-ccm-admin,ou=Groups,DC=cat,DC=com") > -1;
            const isDealerAdmin = profile["http://schemas.cat.com/identity/claims/uniquememberof"].indexOf("cn=ci-ccm-dealeradmin,ou=Groups,DC=cat,DC=com") > -1;
            
            setIsAdmin(isUserAdmin || isSuperAdmin || isDealerAdmin);
        }
        setIsAdmin(true);

        dispatch(loadLists());

        const reloadLists = () => {
            setTimeout(() => {
                dispatch(loadlistsquietly());
                reloadLists();
            }, 10000);
        };
        reloadLists();
    }, [dispatch]);    

    const handleAddList = () => {
        dispatch(addNewList());
    };

    const handleEditList = (list) => {
        dispatch(setAddListModalOpen({ isOpen:true, list}))
    };
    
    return (
        <div className="container container-search-results">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mt-3 mb-3">
                                        My Lists
                                    </div>
                                </div>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-2">
                                    { isAdmin && <button className="btn btn-primary mt-2" style={{ width: "125px", float: "right" }} onClick={handleAddList}>Add New List</button> } 
                                </div>
                            </div>
                        
                        </div>
                        <div className="card-body">
                            
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="w-10">Name</th>
                                        <th className="w-10">Status</th>
                                        <th className="w-10">Total Machines</th>
                                        <th className="w-10">Passed</th>
                                        <th className="w-10">Failed</th>
                                        <th className="w-10">Invalid</th>
                                        <th className="w-10">Eligible</th>
                                        <th className="w-10">Users</th>
                                        <th className="w-10">Date Created</th>
                                        <th className="w-10">Created By</th>
                                        <th className="w-5"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {lists.map(list => {
                                        const createdOn = new Date(list.createdOn);
                                        return (<tr key={list.id}>
                                            <td><Link to={`/lists/${list.id}`} style={{ fontWeight: "bold" }}>{list.name}</Link></td>
                                            <td>
                                                <div className="list-item-status-container">
                                                    <div className="list-item-status">{list.status}</div><div className="list-item-progress"><CircularProgressWithLabel value={list.progressPercentage} /></div>
                                                </div>
                                            </td>
                                            <td>{list.totalMachines}</td>
                                            <td>{list.passed}</td>
                                            <td>{list.failed}</td>
                                            <td>{list.invalid}</td>
                                            <td>{list.eligible}</td>
                                            <td>{list.users}</td>
                                            <td>{ format(createdOn, 'MM/dd/yyyy') }</td>
                                            <td>{list.createdBy}</td>
                                            <td>
                                                <MoreButton
                                                    handleRefresh={() => dispatch(runList({ listId: list.id }))}
                                                    handleArchive={() => dispatch(archiveList({ listId: list.id }))}
                                                    handleEdit={() => handleEditList(list)}
                                                    isAdmin={isAdmin}
                                                ></MoreButton>
                                            </td>
                                        </tr>);
                                    })}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <AddListModal
            ></AddListModal>
        </div>
    );
};

export default ListViewPage;