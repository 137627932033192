import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './CertificationSuccessModal.css';

import { setCertificationSuccessModalOpen } from './searchresults.slice';

const CertificationSuccessModal = () => {

    const dispatch = useDispatch();

    const { serialNumber, certificationHours } = useSelector(state => state.searchresults);
    const { isOpen } = useSelector(state => state.searchresults.certificationSuccessModal);

    const handleClose = () => dispatch(setCertificationSuccessModalOpen(false));
    
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                <div className="success-icon" style={{textAlign: "center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16" style={{height: "48px"}}>
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                </div>
                <br />

                <p className="success-certification-header">Success!</p>

                <p className="success-certification-message">You've successfully certified {serialNumber} at {certificationHours} hours.</p>

                <br />
                <Button variant="primary modal-button" onClick={handleClose}>
                    Ok, Got it
                </Button>

            </Modal.Body>
        </Modal>
    );
};

export default CertificationSuccessModal;