import ccmClient from './ccmClient';
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const validateCertification = async (serialNumber, certificationHours) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/certification/certify', {
        machineSerialNumber: serialNumber,
        certificationHours: certificationHours
    }, { headers });

    return data;
};

export default validateCertification;