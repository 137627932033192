import ccmClient from './ccmClient';
import getCWSService from '../app/getCWSService';

const { getIdToken } = getCWSService();

const deleteNote = async (noteId) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.delete(`/note/${noteId}`, { headers });

    return data;
};

export default deleteNote;