import { Component, useEffect, useState } from 'react';
import getCWSService from '../../app/getCWSService';
import DiagnosticsView from './DiagnosticsView';

import './DiagnosticsTabs.css';
import { useDispatch } from 'react-redux';
import { getMachineDiagnosticsData } from './searchresults.slice';

const DiagnosticsTabs = (props) => {

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState(0);

    const { getUserProfileSync } = getCWSService();
    const profile = getUserProfileSync();
    
    const isUserAdmin = profile["http://schemas.cat.com/identity/claims/uniquememberof"].indexOf("cn=ci-ccm-superadmin,ou=Groups,DC=cat,DC=com") > -1;
    const isSuperAdmin = profile["http://schemas.cat.com/identity/claims/uniquememberof"].indexOf("cn=ci-ccm-admin,ou=Groups,DC=cat,DC=com") > -1;
    const isDealerAdmin = profile["http://schemas.cat.com/identity/claims/uniquememberof"].indexOf("cn=ci-ccm-dealeradmin,ou=Groups,DC=cat,DC=com") > -1;
    const isAdmin = isUserAdmin || isSuperAdmin || isDealerAdmin;

    if(!isAdmin){
        return props.children;
    }

    let tabContent = null;

    const selectResultsTab = () => {
        setSelectedTab(0);
    };
    
    const selectDiagnosticsTab = () => {
        dispatch(getMachineDiagnosticsData(props.serialNumber));
        setSelectedTab(1);
    };

    if(selectedTab === 0) {
        tabContent = (
            <>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link active">Results</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => selectDiagnosticsTab()}>Diagnostics</a>
                    </li>
                </ul>
                {props.children}
            </>
        );
    }
    else {
        tabContent = (
            <>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => selectResultsTab()}>Results</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link active">Diagnostics</a>
                    </li>
                </ul>
                <DiagnosticsView></DiagnosticsView>
            </>
        );
    }

    return tabContent;
};

export default DiagnosticsTabs;