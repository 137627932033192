import { useDispatch } from 'react-redux';

import { getCertificationReport } from '../search-results/searchresults.slice';

const CertificateReportView = (props) => {

    const { sn } = props.match.params;

    const dispatch = useDispatch();

    dispatch(getCertificationReport(sn));

    return <h1>Creating Certificate Report for {sn}</h1>;
};

export default CertificateReportView;