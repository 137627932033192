import { Link, useHistory, NavLink } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

import { getCertificationReport, resetSearchResults } from './features/search-results/searchresults.slice';

import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import getCWSService from "./app/getCWSService";
import getBadgingReport from './api/getBadgingReport';
import getAuditReport from './api/getAuditReport';
import './Header.css'
import catLogo from './resources/cat-logo.png'

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    borderRadius: 0
  },
  formControl: {
    minWidth: 120,
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    '&:before': {
        borderColor: "#FFCC11",
    },
    '&:after': {
        borderColor: "#FFCC11",
    }
  },
  input: {
    color: "white"
  }
}));

const downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const Header = (props) => {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState({ name: "User", organization: "No Claims", userType: ""});
    const [searchText, setSearchText] = React.useState("");
    const anchorRef = React.useRef(null);

    const { 
      serialNumber, 
    } = useSelector(state => state.searchresults);

    const history = useHistory();
    const dispatch = useDispatch();

    const handleKeypress = (e) => {
      if(e.key === 'Enter'){

        if(searchText.length != 8){
          return;
        }

        const searchTextUpper = searchText.toUpperCase();

        history.push(`${searchTextUpper}`);
        setSearchText("");
      }
    };

    const handleGetCertificationReport = () => dispatch(getCertificationReport(serialNumber));

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };
  
    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }
  
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);

    useEffect(() => {

      (async () => {
        const { getUserProfile } = getCWSService();
        const profile = await getUserProfile();
  
        if(!!profile){
          setUserInfo({
            name: profile.name,
            organization: profile["http://schemas.cat.com/identity/claims/o"],
            userType: profile["http://schemas.cat.com/identity/claims/catAfltnCode"]
          });
        }
      })();

    }, []);

    const handleDownloadAuditReport = async (e) => {
      var auditReport = await getAuditReport();
      downloadBase64File("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", auditReport.base64File, "Audit Report.xlsx");
      handleClose(encodeURI);
    };

    const handleDownloadBadgingReport = async (e) => {
      var badgingReport = await getBadgingReport();
      downloadBase64File("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", badgingReport.base64File, "Plate Report.xlsx");
      handleClose(e);
    };

    return <header>
    <div className="navbar navbar-dark bg-dark shadow-sm" style={{ zIndex: "150", paddingBottom: "0px" }}>
      <div className="container">
        <div className="row header-row">
          <div className="col-sm-2">
            <Link to="/" onClick={() => dispatch(resetSearchResults({}))}>
              <img className="cat-logo" src={catLogo} alt=""></img>
            </Link>
          </div>

          <div className="col-sm-4">
            {/* <Link to="/" className="navigation-menu-item" activeClassName="test">
              HOME
            </Link>
            <Link to="/lists" className="navigation-menu-item" activeClassName="test">
              MY LISTS
            </Link> */}
            <NavLink className="navigation-menu-item" exact={true} activeClassName='navigation-menu-item-active' to='/'>HOME</NavLink>
            <NavLink className="navigation-menu-item" activeClassName='navigation-menu-item-active' to='/lists'>MY LISTS</NavLink>
            <NavLink className="navigation-menu-item" activeClassName='navigation-menu-item-active' to='/master-lists'>DEALER PRE-QUALIFICATION LISTS</NavLink>
          </div>

          <div className="col-sm-3">
            
          </div>
          
            
            <div className="col-sm-2" style={{ borderLeft: "1px solid rgba(255, 255, 255, 0.3)", borderRight: "1px solid rgba(255, 255, 255, 0.3)" }}>
              <FormControl className={classes.formControl}>

                <TextField 
                    id="searchBySN" 
                    name="searchBySN" 
                    label="Search by SN or PIN"
                    InputProps={{
                      className: classes.input
                    }}
                    InputLabelProps={{
                      style: { color: '#fff' },
                    }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={handleKeypress}
                />

              </FormControl>
            </div>
            
            
          <div className="col-sm-1">
            
            <div>
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi-person" viewBox="0 0 16 16">
                  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
                </svg>
              </Button>
              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'top right' : 'top right', anchorOrigin: { vertical: 'top', horizontal: 'center'}, anchorPosition: {left:0} }}
                  >
                    <Paper className={classes.menuContainer}>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <MenuItem>{userInfo.name}, {userInfo.organization} </MenuItem>
                          {(userInfo.userType === '001' || userInfo.userType === '014') &&
                          <>
                            <Divider />
                            <MenuItem onClick={handleDownloadAuditReport}>Audit Report</MenuItem>
                            <MenuItem onClick={handleDownloadBadgingReport}>Plate Report</MenuItem>
                            {serialNumber && <MenuItem onClick={handleGetCertificationReport}>Certification Report</MenuItem>}
                          </>
                          }
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            
          </div>
        </div>
       
        
      </div>
    </div>
  </header>;
};

export default Header;