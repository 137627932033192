import ccmClient from './ccmClient';
import getCWSService from '../app/getCWSService';

const { getIdToken } = getCWSService();

const updateList = async (id, name, users) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/lists/' + id, {
        ListId: id,
        ListName: name,
        Users: users
    }, { headers });

    return data;
};

export default updateList;