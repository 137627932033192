import ccmClient from './ccmClient';
import getCWSService from '../app/getCWSService';

const { getIdToken } = getCWSService();

const addList = async (name, users, base64file) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/lists', {
        ListName: name,
        Users: users,
        Base64File: base64file
    }, { headers });

    return data;
};

export default addList;