import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './CertifyAllModal.css';
import { runCertification, setCertifyAllModalOpen } from "./masterlistitem.slice";


const CertifyAllModal = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    let { certifyAllModal, list, listItems  } = useSelector(state => state.masterListItems);

    if(!!certifyAllModal.isFiltered) {
        listItems = listItems.filter(x => !!x.isChecked);
    }

    var certifiable = listItems.filter(x => x.status == "Passed");

    const handleClose = () => {
        dispatch(setCertifyAllModalOpen({ isOpen: false, isFiltered: null }));
    };

    const machines = certifiable.map(m => ({ serialNumber: m.serialNumber, smu: m.highestEligibleQualification }))

    const handleCertify = async () => {
        await dispatch(runCertification({ machines }));
        dispatch(setCertifyAllModalOpen({ isOpen: false, isFiltered: null }));
        setTimeout(() => {
            history.push(`/master-lists`);
        }, 300);
    };
    
    return (
        <Modal
            show={certifyAllModal.isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                
                <p className="certify-modal-header mt-30">Certify All</p>

                {!!certifiable && certifiable.length > 0 ? (
                    <p className="certify-modal-message mt-10">We found {certifiable.length} machines that can be certified. Would you like to certify them all?</p>
                ) : (
                    <p className="certify-modal-message mt-10">We found no machines that can be certified.</p>
                ) }

            </Modal.Body>
            <Modal.Footer>
                {!!certifiable && certifiable.length > 0 ? (
                    <>
                        <Button type="button" variant="secondary modal-button" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="primary" onClick={handleCertify} disabled={!certifiable || certifiable.length === 0}>
                            Certify
                        </Button>
                    </>
                ) : (
                    <>
                        <Button type="button" variant="secondary modal-button" onClick={handleClose}>
                            Ok, Got It
                        </Button>
                    </>
                ) }
            </Modal.Footer>
        </Modal>
    );
};

export default CertifyAllModal;