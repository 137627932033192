import { format } from 'date-fns';
import React, { Component, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table';

import { selectDiagnosticsData } from './searchresults.slice';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "30%",
        marginLeft: 0,
        // float: "right"
    }
}));

const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <TextField 
                id="searchText" 
                name="searchText" 
                label="Search" 
                value={value || ""} 
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={``}
            />
        </FormControl>
    )
};

const formatDate = (val) => {
    if(!val) return "N/A";
    var parsedDate = new Date(val);
    return format(parsedDate, 'MM/dd/yyyy');
}

const renderNAIfEmpty = (val) => {
    return !val || val === '' ? "N/A" : val;
};

const DiagnosticsView = (props) => {

    const diagnosticsData = useSelector(selectDiagnosticsData);

    const [selectedTab, setSelectedTab] = useState(0);

    const pmSchemaColumns = useMemo(
        () => [
          {
            id: 'code',
            Header: 'Code',
            accessor: interval => renderNAIfEmpty(interval.code),
          },
          {
            id: 'description',
            Header: 'Description',
            accessor: interval => renderNAIfEmpty(interval.description),
          },
          {
            id: 'firstInterval',
            Header: 'First Interval',
            accessor: interval => renderNAIfEmpty(interval.firstInterval),
          },
          {
            id: 'nextInterval',
            Header: 'Next Interval',
            accessor: interval => renderNAIfEmpty(interval.nextInterval),
          },
          {
            id: 'jobCode',
            Header: 'Job Code',
            accessor: interval => renderNAIfEmpty(interval.jobCode),
          },
          {
            id: 'jobCodeDescription',
            Header: 'Job Description',
            accessor: interval => renderNAIfEmpty(interval.jobCodeDescription),
          },
          {
            id: 'componentCode',
            Header: 'Component Code',
            accessor: interval => renderNAIfEmpty(interval.componentCode),
          },
          {
            id: 'componentCodeDescription',
            Header: 'Component Description',
            accessor: interval => renderNAIfEmpty(interval.componentCodeDescription),
          },
          {
            id: 'modCode',
            Header: 'Mod Code',
            accessor: interval => renderNAIfEmpty(interval.modCode),
          },
          {
            id: 'modCodeDescription',
            Header: 'Mod Code Description',
            accessor: interval => renderNAIfEmpty(interval.modCodeDescription),
          }
        ],
        []
    );

    const cvaColumns = useMemo(
        () => [
          {
            id: 'serialNumber',
            Header: 'Serial Number',
            accessor: cva => renderNAIfEmpty(cva.serialNumber),
          },
          {
            id: 'goToMarket',
            Header: 'Market',
            accessor: cva => renderNAIfEmpty(cva.goToMarket),
          },
          {
            id: 'dealerName',
            Header: 'Dealer',
            accessor: cva => renderNAIfEmpty(cva.dealerName),
          },
          {
            id: 'startDate',
            Header: 'Start Date',
            accessor: cva => formatDate(cva.startDate),
          },
          {
            id: 'endDate',
            Header: 'End Date',
            accessor: cva => formatDate(cva.endDate),
          },
          {
            id: 'startSMU',
            Header: 'Start SMU',
            accessor: cva => renderNAIfEmpty(cva.startSMU),
          },
          {
            id: 'contractDuration',
            Header: 'Contract Duration',
            accessor: cva => renderNAIfEmpty(cva.contractDuration),
          },
          {
            id: 'expirationScenario',
            Header: 'Expiration Scenario',
            accessor: cva => renderNAIfEmpty(cva.expirationScenario),
          },
          {
            id: 'lastUpdateDate',
            Header: 'Last Update Date',
            accessor: cva => formatDate(cva.lastUpdateDate),
          }
        ],
        []
    );

    const workOrderColumns = useMemo(
        () => [
          {
            id: 'workOrderNumber',
            Header: 'Work Order #',
            accessor: cva => renderNAIfEmpty(cva.workOrderNumber),
          },
          {
            id: 'dealerCode',
            Header: 'Dealer Code',
            accessor: cva => renderNAIfEmpty(cva.dealerCode),
          },
          {
            id: 'openDate',
            Header: 'Date Opened',
            accessor: cva => formatDate(cva.openDate),
          },
          {
            id: 'machineSerialNumber',
            Header: 'Serial Number',
            accessor: cva => renderNAIfEmpty(cva.machineSerialNumber),
          },
          {
            id: 'smu',
            Header: 'SMU',
            accessor: cva => renderNAIfEmpty(cva.smu),
          },
          {
            id: 'jobCode',
            Header: 'Job Code',
            accessor: cva => renderNAIfEmpty(cva.jobCode),
          },
          {
            id: 'componentCode',
            Header: 'Component Code',
            accessor: cva => renderNAIfEmpty(cva.componentCode),
          },
          {
            id: 'modCode',
            Header: 'Mod Code',
            accessor: cva => renderNAIfEmpty(cva.modCode),
          }
        ],
        []
    );

    const schemaTable = useTable({ columns: pmSchemaColumns, data: diagnosticsData?.schema?.items || [] }, useGlobalFilter);
    const cvaTable = useTable({ columns: cvaColumns, data: diagnosticsData?.cvAs?.items  || [] }, useGlobalFilter);
    const workOrderTable = useTable({ columns: workOrderColumns, data: diagnosticsData?.workOrders?.items  || [] }, useGlobalFilter);

    if(!diagnosticsData) return null;



    return (
        <>
            <ul className="nav nav-tabs" style={{ marginTop: "15px" }}>
                <li className="nav-item">
                    <a className={`nav-link ${selectedTab === 0 ? " active" : ""}`} onClick={() => setSelectedTab(0)}>PM Schema</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${selectedTab === 1 ? " active" : ""}`} onClick={() => setSelectedTab(1)}>CVAs</a>
                </li>
                <li className="nav-item">
                    <a className={`nav-link ${selectedTab === 2 ? " active" : ""}`} onClick={() => setSelectedTab(2)}>Work Orders</a>
                </li>
            </ul>

            {selectedTab === 0 ? (
                <table {...schemaTable.getTableProps()} className='table'>
                    <thead>
                        <tr>
                            <th
                            colSpan={schemaTable.visibleColumns.length}
                            style={{
                                textAlign: 'left',
                            }}
                            >
                            <GlobalFilter
                                preGlobalFilteredRows={schemaTable.preGlobalFilteredRows}
                                globalFilter={schemaTable.state.globalFilter}
                                setGlobalFilter={schemaTable.setGlobalFilter}
                            />
                            </th>
                        </tr>
                        {
                            schemaTable.headerGroups.map(headerGroup => (
                                
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))
                                }
                                </tr>
                            ))
                        }
                    </thead>
                    <tbody {...schemaTable.getTableBodyProps()}>
                    {
                        schemaTable.rows.map(row => {
                            schemaTable.prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                {
                                    row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })
                                }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            ) : (<></>)}


            {selectedTab === 1 ? (
                <table {...cvaTable.getTableProps()} className='table'>
                    <thead>
                    <tr>
                        <th
                        colSpan={cvaTable.visibleColumns.length}
                        style={{
                            textAlign: 'left',
                        }}
                        >
                        <GlobalFilter
                            preGlobalFilteredRows={cvaTable.preGlobalFilteredRows}
                            globalFilter={cvaTable.state.globalFilter}
                            setGlobalFilter={cvaTable.setGlobalFilter}
                        />
                        </th>
                    </tr>
                    {
                        cvaTable.headerGroups.map(headerGroup => (
                            
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))
                            }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody {...cvaTable.getTableBodyProps()}>
                    {
                        cvaTable.rows.map(row => {
                            cvaTable.prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                {
                                    row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })
                                }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            ) : (<></>)}

            {selectedTab === 2 ? (
                <table {...workOrderTable.getTableProps()} className='table'>
                    <thead>
                    <tr>
                        <th
                        colSpan={workOrderTable.visibleColumns.length}
                        style={{
                            textAlign: 'left',
                        }}
                        >
                        <GlobalFilter
                            preGlobalFilteredRows={workOrderTable.preGlobalFilteredRows}
                            globalFilter={workOrderTable.state.globalFilter}
                            setGlobalFilter={workOrderTable.setGlobalFilter}
                        />
                        </th>
                    </tr>
                    {
                        workOrderTable.headerGroups.map(headerGroup => (
                            
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))
                            }
                            </tr>
                        ))
                    }
                    </thead>
                    <tbody {...workOrderTable.getTableBodyProps()}>
                    {
                        workOrderTable.rows.map(row => {
                            workOrderTable.prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                {
                                    row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })
                                }
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            ) : (<></>)}
        </>
    );
};

export default DiagnosticsView;