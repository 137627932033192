import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { setLoading } from '../loader/loader.slice';
import getMasterLists from '../../api/getMasterLists';
import runDealerListsApi from '../../api/runDealerLists';

const initialState = {
    lists: [],
    totalStats: [{ prequalified: 0, eligible: 0, certified: 0 }]
};

export const loadLists = createAsyncThunk('master-lists/loadlists', async (param, { dispatch, getState }) => {

    dispatch(setLoading(true));

    const listsResponse = await getMasterLists();

    dispatch(setLoading(false));

    return listsResponse;
});

export const runDealerLists = createAsyncThunk('master-lists/runDealerLists', async (params, { dispatch, getState }) => {

  const { lists } = getState().masterLists;

  const dealerCodes = lists.map(l => l.dealerCode);

  runDealerListsApi(dealerCodes)
      .then(res => console.log("Lists queued", res));

  return {
  };
});

const masterListSlice = createSlice({
  name: 'master-lists',
  initialState,
  reducers: {

  },

  extraReducers: builder => {
    builder
    
      .addCase(loadLists.fulfilled, (state, action) => {
        state.lists = action.payload.lists;
        const prequalified = state.lists.map(x => x.passedPrequalification).reduce((a, b) => a + b);
        const eligible = state.lists.map(x => x.eligible).reduce((a, b) => a + b);
        const certified = state.lists.map(x => x.certified).reduce((a, b) => a + b);
        state.totalStats = [ { prequalified, eligible, certified }, { prequalified, eligible, certified } ];
      })

      ;
  }
});

export const {  } = masterListSlice.actions;

export default masterListSlice.reducer;