import { useHistory } from "react-router-dom";
import './SearchPage.css'
import backgroundImage from './../../resources/background.png'
import { useState } from "react";
import serialNumberValidator from "../../validators/serialNumberValidator";
import validate from "../../validators/validate";
import requiredValidator from "../../validators/requiredValidator";

const SearchPage = (props) => {

    const history = useHistory();
    var [searchText, setSearchText ] = useState("");
    var [searchValidation, setSearchValidation ] = useState("");
    var [searchTouched, setSearchTouched] = useState(false);

    const searchBySerial = (e) => {
        if(!!validate(searchText, [requiredValidator, serialNumberValidator])){
            return;
        }

        const searchTextUpper = searchText.toUpperCase();

        history.push(`searchresults/${searchTextUpper}`);
    };

    const goToLists = () => {
        history.push(`/lists`);
    };

    const searchTextChanged = (e) => {

        const valResult = validate(e.target.value, [requiredValidator, serialNumberValidator]);

        if(!!valResult){
            setSearchValidation(valResult);
        }

        setSearchText(e.target.value);
    };

    const searchTextTouched = () => {
        setSearchTouched(true);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchBySerial();
        }
    };

    return (
        <div>
            <div className="background-container">
                <img className="background-image" src={backgroundImage} alt=""></img>
            </div>
            <div className="container title-container">
                <h1>Cat<span className="copyright">®</span> Certified Maintained</h1>
            </div>
            <div className="container search-container">
                <div className="row search-row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <div className="input-group mb-3 search-input-group">
                            <input 
                                type="text" 
                                required 
                                className="form-control search-input" 
                                placeholder="Search by serial number or PIN" 
                                aria-label="Search by serial number or PIN" 
                                aria-describedby="basic-addon2" 
                                onChange={searchTextChanged} 
                                value={searchText}
                                onBlur={searchTextTouched}
                                onKeyDown={handleKeyDown}
                            ></input>
                            <div className="input-group-append">
                                <button className="btn btn-search" type="button" onClick={searchBySerial}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="btn-search-icon" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div style={{textAlign:"center", color: "red", fontSize: "14px"}}>
                            {searchTouched && searchValidation}
                        </div>
                    </div>
                    <div className="col-sm-4"></div>
                </div>
                <div className="row description-row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <p className="description">A CCM equipment designation offers many benefits, from expert dealer maintenance to helping maximize resale value. Any CVA with dealer labor and meeting OMM requirements since initial delivery is eligible for a CCM certification. With ongoing dealer-performed maintenance, Cat customers will enjoy the security of knowing they're meeting maintenance guidelines and avoiding costly machine failures. When it comes time to resell their equipment, a CCM designation will stand out among other machines in the used market. <br></br><br></br><small>**CVAs and CCM designation are subject to restrictions and availability.</small></p>
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                <div className="row description-row">
                    <div className="col-sm-5"></div>
                    <div className="col-sm-2" style={{ textAlign: "center" }}>
                        <button className="btn btn-primary" onClick={goToLists} >My Lists</button>
                    </div>
                    <div className="col-sm-5"></div>
                </div>
            </div>
        </div>
    );
};

export default SearchPage;