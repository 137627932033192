import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const runDealerLists = async (dealerCodes) => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post(`/master-lists/run`, { dealerCodes }, { headers });

    return data;
};

export default runDealerLists;