import { useSelector } from 'react-redux';
import ReactLoaderSpinner from "react-loader-spinner";

const spinnerStyle = { 
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
};

const backgroundStyle = {
    position: "fixed", 
    width: "100%", 
    height: "100%", 
    backgroundColor: "rgba(0,0,0,0.35)",
    zIndex: "9999"
};

const Loader = (props) => {

    const { 
        isLoading
    } = useSelector(state => state.loader);

    if(isLoading){
        return (
            <div style={backgroundStyle}>
                <div style={spinnerStyle}>
                    <ReactLoaderSpinner
                        type="Puff"
                        color="#FFCC11"
                        height={100}
                        width={100}
                        timeout={300000}
                        />
                </div>
            </div>
        );
    }

    return <></>;
};

export default Loader;