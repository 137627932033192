import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { loadFilterStatistics, loadListDetails, runSerialNumbers, setAllItemsChecked, setCertifyAllModalOpen, setCertifyModalOpen, setFiltersSelected, setItemChecked, setPaging } from './listitem.slice';

import './ListItemViewPage.css';

import { withStyles } from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import Checkbox from "@material-ui/core/Checkbox";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import VerifiedIcon from '@mui/icons-material/Verified';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { useMemo } from 'react';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import Drawer from '@mui/material/Drawer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CloseIcon from '@mui/icons-material/Close';

import CertifyModal from './CertifyModal';
import CertifyAllModal from './CertifyAllModal';

const displayNotAvailable = (content) => {
    return !content ? "N/A" : content;
}

const useClasses = stylesElement => {
    const theme = useTheme();
    return useMemo(() => {
      const rawClasses = typeof stylesElement === 'function'
        ? stylesElement(theme)
        : stylesElement;
      const prepared = {};
  
      Object.entries(rawClasses).forEach(([key, value = {}]) => {
        prepared[key] = css(value);
      });
  
      return prepared;
    }, [stylesElement, theme]);
};

const styles = {
    paperRoot: {
        backgroundColor: 'transparent !important',
        borderRadius: "0 !important",
        boxShadow: "none !important",
        height: "55px",
        marginLeft: "-17px"
    },
    accordionRoot: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
        width: "375px"
    },
    drawerRoot: {
        overflow: "hidden"
    }
};

const CatCheckbox = withStyles({
    root: {
      color: "#43485C",
      "&$checked": {
        color: "#FFCC11",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
          },
          "& .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: "#43485C",
            zIndex: -1
          }
      }
    },
    checked: {}
  })((props) => <Checkbox color="default" {...props} />);

const ListItemViewPage = (props) => {

    const { listId } = props.match.params;

    const anchorRef = React.useRef(null);
    const classes = useClasses(styles);
    const history = useHistory();
    const dispatch = useDispatch();

    const { 
        list,
        listItems,
        listStatistics,
        allItemsChecked,
        anyItemChecked,
        listItemCount,
        itemsPerPage,
        page,
        filtersSelected
    } = useSelector(state => state.listitems);

    const [contextMenuOpen, setContextMenuOpen] = React.useState(false);
    const [filterOpen, setFilterOpen] = React.useState(false);

    const loadPage = () => {
        dispatch(loadListDetails( { listId, page, itemsPerPage } ));
        dispatch(loadFilterStatistics({ listId }));
    };

    useEffect(() => {
        loadPage();
    }, [dispatch]);

    useEffect(() => {
        setContextMenuOpen(anyItemChecked);
    }, [anyItemChecked]);

    const handleAllItemsSelectionChanged = (e) => {
        dispatch(setAllItemsChecked(e.target.checked));
    };

    const handleContextMenuClose = (event) => {    
        setContextMenuOpen(false);
        dispatch(setCertifyAllModalOpen({ isOpen: true, isFiltered: true }));
    };
    
    const totalPages = Math.floor(listItemCount / itemsPerPage) + (listItemCount % itemsPerPage > 0 ? 1 : 0);

    const handleRefreshItem = (item) => {
        dispatch(runSerialNumbers({ listId, serialNumbers: [item.serialNumber] }));
        setTimeout(() => {
            history.push(`/lists`);
        }, 1000);
    }

    const handleGoToDetails = (event, item) => {
        event.preventDefault();
        setTimeout(() => {
            history.push(`/searchresults/${item.serialNumber}`);
        }, 200);
    }

    const handleStatusFilterChanged = (e, item) => {
        const statusFilters = [...filtersSelected.status];

        const itemIndex = statusFilters.indexOf(item.name);
        if(itemIndex > -1) {
            statusFilters.splice(itemIndex, 1);
        }
       
        if(e.target.checked){
            statusFilters.push(item.name);
        }
        
        dispatch(setFiltersSelected({status: statusFilters, errors: filtersSelected.errors}));
    };

    const handleErrorFilterChanged = (e, item) => {
        const errorFilters = [...filtersSelected.errors];

        const itemIndex = errorFilters.indexOf(item.name);
        if(itemIndex > -1) {
            errorFilters.splice(itemIndex, 1);
        }
       
        if(e.target.checked){
            errorFilters.push(item.name);
        }
        
        dispatch(setFiltersSelected({status: filtersSelected.status, errors: errorFilters}));
    };

    const handleApplyFilter = () => {
        dispatch(setPaging( { listId, page: 1, itemsPerPage } ));
        setFilterOpen(false);
    };

    const pmSchemaCheck = (item) => {

        if(item.errors === "Invalid"){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(item.status === "Certified") {
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        return !item.missingPMSchema ? <a style={{ color: "#008A45", fontWeight: "bold" }}>Pass</a> : <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Fail</a>;
    };

    const machineAgeCheck = (item) => {

        if(item.status === "Certified") {
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.missingPMSchema){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        return !item.machineTooOld ? <a style={{ color: "#008A45", fontWeight: "bold" }}>Pass</a> : <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Fail</a>;
    };

    const minimumHoursCheck = (item) => {

        if(item.status === "Certified") {
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.missingPMSchema){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.machineTooOld){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        return !!item.exceedsMinimumHours ? <a style={{ color: "#008A45", fontWeight: "bold" }}>Pass</a> : <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Fail</a>;
    };

    const workOrderCheck = (item) => {

        if(item.status === "Certified") {
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.missingPMSchema){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!item.exceedsMinimumHours){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.machineTooOld){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        return !item.missingWorkOrders ? <a style={{ color: "#008A45", fontWeight: "bold" }}>Pass</a> : <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Fail</a>;
    };

    const cvaCheck = (item) => {

        if(item.status === "Certified") {
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.missingPMSchema){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!item.exceedsMinimumHours){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.machineTooOld){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        if(!!item.missingWorkOrders){
            return <a style={{ color: "#43485C", fontWeight: "bold" }}>Not checked</a>;
        }

        return !item.missingCVA ? <a style={{ color: "#008A45", fontWeight: "bold" }}>Pass</a> : <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Fail</a>;
    };

    return (
        <div className="container container-search-results">
            <div className="row">
                <div className="col-sm-12">
                    <div id="list-item-container" className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-sm-6" style={{ fontSize: "18px" }}>
                                    <div className="mt-3 mb-3">
                                        <Link to="/lists" style={{ color: "#43485C", paddingRight: "10px", display: "inline-block", paddingLeft: "10px" }}><ArrowBackIcon /></Link> {list.name}
                                    </div>
                                </div>
                                <div className="col-sm-3"></div>
                                <div className="col-sm-3">
                                    <button className="btn btn-primary mt-2" style={{ width: "125px", marginRight: "10px" }} onClick={() => dispatch(setCertifyAllModalOpen({ isOpen: true }))} >Certify All</button>
                                    <button className="btn btn-secondary mt-2" style={{ width: "125px" }} onClick={() => setFilterOpen(true)} > <FilterAltIcon /> Filters</button>
                                </div>
                            </div>
                        
                        </div>
                        <div className="card-body">

                            <div className="container summary-container">

                                <div className="row">

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h6 className="list-summary-header">LIST STATUS</h6>
                                    </div>

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h4 className="list-summary-header">TOTAL MACHINES</h4>
                                    </div>

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h4 className="list-summary-header">USERS</h4>
                                    </div>

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h4 className="list-summary-header">DATE CREATED</h4>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h6 className="list-summary-value">{list.status}</h6>
                                    </div>

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h4 className="list-summary-value">{list.totalMachines}</h4>
                                    </div>

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h4 className="list-summary-value">{list.users}</h4>
                                    </div>

                                    <div className="col-sm-3" style={{ maxWidth: "25%" }}>
                                        <h4 className="list-summary-value">{!!list.createdOn ? format(list.createdOn, 'MM/dd/yyyy') : "N/A"}</h4>
                                    </div>

                                </div>

                            </div>
                            
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="list-item-header w-5">
                                            <div>
                                                <div ref={anchorRef}>
                                                    <CatCheckbox 
                                                    id="composition-button"
                                                        className="list-item-checkbox" 
                                                        checked={allItemsChecked} 
                                                        onChange={handleAllItemsSelectionChanged} 
                                                        aria-controls={contextMenuOpen ? 'composition-menu' : undefined}
                                                        aria-expanded={contextMenuOpen ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                    />
                                                    <Popper
                                                        open={contextMenuOpen}
                                                        anchorEl={anchorRef.current}
                                                        role={undefined}
                                                        placement="top-start"
                                                        transition
                                                        disablePortal
                                                    >
                                                    {({ TransitionProps, placement }) => (
                                                        <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin: 'left top',
                                                        }}
                                                        >
                                                        <Paper
                                                            classes={{ root: classes.paperRoot }}
                                                        >
                                                            <MenuList
                                                                autoFocusItem={contextMenuOpen}
                                                                id="composition-menu"
                                                                aria-labelledby="composition-button"
                                                            >
                                                                <MenuItem onClick={handleContextMenuClose}>
                                                                    <svg width="116" height="48" viewBox="0 0 116 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M0 0L116 0V40H31.85L22.05 48L13.25 40H0V0Z" fill="#FFCC11"/>
                                                                    </svg>
                                                                    <b className="popper-certify-text">Certify</b>
                                                                </MenuItem>
                                                                
                                                            </MenuList>
                                                        </Paper>
                                                        </Grow>
                                                    )}
                                                    </Popper>
                                                </div>
                                                
                                            </div>
                                        </th>
                                        <th className="list-item-header w-8">Serial Number</th>
                                        <th className="list-item-header w-8">Machine</th>
                                        <th className="list-item-header w-8">Missing PM Schema</th>
                                        <th className="list-item-header w-8">Machine too old</th>
                                        <th className="list-item-header w-8">Meets minimum hours</th>
                                        <th className="list-item-header w-8">Missing work orders</th>
                                        <th className="list-item-header w-8">Missing CVA</th>
                                        <th className="list-item-header w-5">Eligible</th>
                                        <th className="list-item-header w-8">Eligible SMU</th>
                                        <th className="list-item-header w-15"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItems.map(item => {
                                        return (
                                        <tr key={item.id}>
                                            <td>
                                                <div>
                                                    <CatCheckbox className="list-item-checkbox" checked={item.isChecked} onChange={(e) => dispatch(setItemChecked({ item: item, isChecked: e.target.checked }))} />
                                                </div>
                                            </td>
                                            <td><a href="#" onClick={(e) => handleGoToDetails(e, item)}>{item.serialNumber}</a></td>
                                            <td>{displayNotAvailable(item.machineType)}</td>
                                            <td>{pmSchemaCheck(item)}</td>
                                            <td>{machineAgeCheck(item)}</td>
                                            <td>{minimumHoursCheck(item)}</td>
                                            <td>{workOrderCheck(item)}</td>
                                            <td>{cvaCheck(item)}</td>
                                            <td>
                                                {item.status === 'Failed' ?  
                                                    (
                                                        item.errors === 'Invalid' ?
                                                        <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Invalid</a> : 
                                                        <a style={{ color: "#ED1C24", fontWeight: "bold" }}>Ineligible</a>
                                                    ) :
                                                    (
                                                        item.status === "Certified" ?
                                                        <a style={{ color: "#008A45", fontWeight: "bold" }}>Certified</a> :
                                                        <a style={{ color: "#008A45", fontWeight: "bold" }}>Eligible</a>
                                                    )
                                                }
                                            </td>
                                            <td>{displayNotAvailable(item.highestEligibleQualification)}</td>
                                            <td>
                                                <button 
                                                    className="btn btn-secondary" 
                                                    style={{ width: "130px", marginRight: "10px" }}
                                                    disabled={item.status === "In Progress" || item.status === "Pending" || item.status === "Certified"}
                                                    onClick={() => handleRefreshItem(item)}
                                                >
                                                    <RefreshIcon style={{ paddingBottom: "2px" }} /> <span style={{ width: "5px" }}></span> Refresh
                                                </button>
                                                <button 
                                                    className="btn btn-secondary" 
                                                    style={{ width: "130px" }}
                                                    disabled={item.status !== "Passed"}
                                                    onClick={() => dispatch(setCertifyModalOpen({ isOpen: true, listItem: item }))}
                                                >
                                                    <VerifiedIcon style={{ paddingBottom: "2px" }} /> <span style={{ width: "5px" }}></span> Certify
                                                </button>
                                            </td>
                                        </tr>);
                                    })}
                                </tbody>
                            </table>

                            <div className="row paging-container">
                                <div className="col-sm-5"><p className="pagination-items-per-page" style={{ marginLeft: "10px" }}>Viewing {page === 1 ? 1 : (page - 1) * itemsPerPage} - {page === totalPages ? listItemCount : (page - 1) * itemsPerPage + itemsPerPage} of {listItemCount}</p></div>
                                <div className="col-sm-3"><Pagination count={totalPages} page={page} shape="rounded" onChange={(e, value) => dispatch(setPaging({ listId, page: value, itemsPerPage }))} /></div>
                                <div className="col-sm-4">
                                    <Select
                                        value={itemsPerPage}
                                        onChange={(e) => dispatch(setPaging({ listId, page: 1, itemsPerPage: e.target.value }))}
                                        variant="standard"
                                        style={{ height: "30px", width: "90px", float: "right", marginRight: "15px", marginTop: "-2px" }}
                                        >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={25}>25</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                        <MenuItem value={250}>250</MenuItem>
                                        <MenuItem value={500}>500</MenuItem>
                                        <MenuItem value={1000}>1000</MenuItem>
                                    </Select>
                                    <p className="pagination-items-per-page" style={{ float: "right" }}>Items per page</p>
                                </div>
                            </div>
                            

                        </div>
                    </div>
                </div>

                <Drawer
                        anchor="right"
                        open={filterOpen}
                        BackdropProps={{ invisible: true }}
                        className={classes.drawerRoot}
                        variant="temporary"
                        disableBackdropTransition={true}
                    >
                        <div>
                            <div className="row filter-header-container">
                                <div className="col-sm-6">
                                    <h6 className="filter-header">Filters</h6>
                                </div>
                                <div className="col-sm-4">
                                    <a className="list-summary-header" style={{ cursor: "pointer", marginTop: "21px", display: "block", textDecoration: "underline" }} onClick={loadPage}>SHOW ALL</a>
                                </div>
                                <div className="col-sm-2">
                                    <a onClick={() => setFilterOpen(false)} style={{ cursor: "pointer", marginTop: "16px", display: "block" }}><CloseIcon /></a>
                                </div>
                            </div>
                            <Accordion className={classes.accordionRoot} defaultExpanded={true}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className="filter-category-header">Status</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        {listStatistics.filter(x => x.category === "Status").map((item) => (
                                            <FormControlLabel control={<CatCheckbox onChange={(e) => handleStatusFilterChanged(e, item)} checked={filtersSelected.status.indexOf(item.name) > -1} />} label={`${item.name} (${item.count})`} />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={classes.accordionRoot} defaultExpanded={true}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                                >
                                <Typography className="filter-category-header">Errors</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormGroup>
                                        {listStatistics.filter(x => x.category === "Errors").map((item) => (
                                            <FormControlLabel control={<CatCheckbox onChange={(e) => handleErrorFilterChanged(e, item)}  checked={filtersSelected.errors.indexOf(item.name) > -1} />} label={`${item.name} (${item.count})`} />
                                        ))}
                                    </FormGroup>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="filter-button-container">
                            <button type="button" className="btn btn-secondary filter-button-secondary" onClick={() => setFilterOpen(false)}>Cancel</button>
                            <button type="button" className="btn btn-primary filter-button-primary" onClick={handleApplyFilter}>Apply</button>
                        </div>
                    
                </Drawer>

            </div>

            <CertifyModal />
            <CertifyAllModal />

        </div>
    );
};

export default ListItemViewPage;