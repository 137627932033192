import ccmClient from './ccmClient';
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getCertificationReport = async (serialNumber) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.get(`/export/certification-report-export/${serialNumber}`, { headers });

    return data;
};

export default getCertificationReport;