import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import validateCertificationApi from '../../api/validateCertification';
import getCertificationReportApi from '../../api/getCertificationReport';
import certifyApi from '../../api/certify';
import getMachineDiagnosticsDataApi from '../../api/getMachineDiagnosticsData';
import selectWorkOrderApi from '../../api/selectWorkOrder';

import { setLoading } from '../loader/loader.slice';
import validateCertificationMaxSMU from '../../api/validateCertificationMaxSMU';

const downloadBase64File = (contentType, base64Data, fileName) => {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const initialState = {
    serialNumber: '',
    market: '',
    maxSMUFound: 0,
    maintenanceHistory: [],
    certificateInfos: [],
    maintenanceSchedule: [],
    certificationHours: 0,
    diagnosticsData: null,
    failureDetails: null,
    certificationSuccessModal: {
      isOpen: false
    },
    certificationFailedModal: {
      isOpen: false,
      failureDetails: {}
    },
    reportModal: {
      isOpen: false
    },
    errorModal: {
      isOpen: false,
      missingPMSchema: false,
      invalidSerialNumber: false
    },
    certifyModal: {
      isOpen: false
    }
};

export const getMachineDetails = createAsyncThunk('searchresults/getMachineDetails', async (serialNumber, { dispatch }) => {

  dispatch(setLoading(true));
  dispatch(setErrorModalOpen(false));

  try {
  
    const machineCheckResponse = await validateCertificationMaxSMU(serialNumber);

    var minSMU = machineCheckResponse.info.market === 'GCI' ? 3000 : 1500;
    const initialSMU = machineCheckResponse.info.maxSMUFound > minSMU ? Math.floor(machineCheckResponse.info.maxSMUFound / 500) * 500 : minSMU;
    
    const response = {
        serialNumber: machineCheckResponse.info.machineSerialNumber,
        market: machineCheckResponse.info.market,
        maxSMUFound: machineCheckResponse.info.maxSMUFound,
        missingPMSchema: machineCheckResponse.info.missingPMSchema,
        invalidSerialNumber: machineCheckResponse.info.invalidSerialNumber,
        maintenanceHistory: machineCheckResponse.info.items.map(x => ({
          ...x,
          isMissing: !x.serviceCompleted
        })),
        certificateInfos: machineCheckResponse.certificates,
        maintenanceSchedule: machineCheckResponse.intervals,
        certificationHours: initialSMU,
        failureDetails: machineCheckResponse?.info?.failureDetails
    };

    return response;
  }
  catch(err) {
    throw err;
  }
  finally {
    dispatch(setLoading(false));
  }
});

export const validateCertification = createAsyncThunk('searchresults/validateCertification', async ({ serialNumber, certificationHours }, { dispatch }) => {

  dispatch(setLoading(true));

  const response = await validateCertificationApi(serialNumber, certificationHours);

  dispatch(setLoading(false));

  return {
    maintenanceHistory: response.info.items.map(x => ({
      ...x,
      isMissing: !x.serviceCompleted
    })),
    failureDetails: response?.info?.failureDetails,
    passedValidation: response.info.verified,
  };
});

export const certify = createAsyncThunk('searchresults/certify', async ({ serialNumber, certificationHours }, { dispatch }) => {

  dispatch(setLoading(true));

  try {
    const response = await certifyApi(serialNumber, certificationHours);

    return {
      maintenanceHistory: response.info.items.map(x => ({
        ...x,
        isMissing: !x.serviceCompleted
      })),
      certificateInfos: response?.certificates,
      machineCertified: response.info.verified,
      failureDetails: response.info.failureDetails
    };
  }
  catch (err) {
    console.log("Error while certifying", err)
    throw err;
  }
  finally {
    dispatch(setLoading(false));
  }  
});

export const getCertificationReport = createAsyncThunk('searchresults/getCertificationReport', async (serialNumber, { dispatch }) => {

  dispatch(setLoading(true));

  const certificationReport = await getCertificationReportApi(serialNumber);

  downloadBase64File("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", certificationReport.base64File, "Certification Report.xlsx");

  dispatch(setLoading(false));
});

export const getMachineDiagnosticsData = createAsyncThunk('searchresults/getMachineDiagnosticsData', async (serialNumber, { dispatch }) => {

  dispatch(setLoading(true));

  const diagnosticsData = await getMachineDiagnosticsDataApi(serialNumber);

  dispatch(setLoading(false));

  return diagnosticsData?.data;
});

export const selectWorkOrder = createAsyncThunk('searchresults/selectWorkOrder', async ({workOrderNumber, interval, machineSerialNumber}, {dispatch}) => {

  dispatch(setLoading(true));

  const response = await selectWorkOrderApi(machineSerialNumber, interval, workOrderNumber);
  dispatch(getMachineDetails(machineSerialNumber));

  return response;
});

const searchresultsSlice = createSlice({
  name: 'searchresults',
  initialState,
  reducers: {

    resetSearchResults(state, action) {
      state.serialNumber = "";
    },

    setCertificationHours(state, action) {
        state.certificationHours = action.payload;
    },

    setCertificationSuccessModalOpen(state, action) {
      state.certificationSuccessModal.isOpen = action.payload;
    },

    setCertificationFailedModalOpen(state, action) {
      state.certificationFailedModal.isOpen = action.payload;
    },

    setReportModalOpen(state, action) {
      state.reportModal.isOpen = action.payload;
    },

    setErrorModalOpen(state, action) {
      state.errorModal.isOpen = action.payload;
      state.errorModal.missingPMSchema = "";
    },

    setCertifyModalOpen(state, action) {
      state.certifyModal.isOpen = action.payload;
    },

  },
  extraReducers: builder => {
    builder

      .addCase(getMachineDetails.pending, (state, action) => {
        state = {...initialState};
      })

      .addCase(getMachineDetails.fulfilled, (state, action) => {
        state.serialNumber = action.payload.serialNumber;
        state.market = action.payload.market;
        state.maxSMUFound = action.payload.maxSMUFound;
        state.maintenanceHistory = !!action.payload.maintenanceHistory ? action.payload.maintenanceHistory : [];
        state.certificateInfos = action.payload.certificateInfos;
        state.maintenanceSchedule = !!action.payload.maintenanceSchedule ? action.payload.maintenanceSchedule : [];
        state.certificationHours = action.payload.certificationHours;

        if(!!action.payload.missingPMSchema){
          state.errorModal.isOpen = true;
          state.errorModal.missingPMSchema = true;
        }

        if(!!action.payload.invalidSerialNumber){
          state.errorModal.isOpen = true;
          state.errorModal.invalidSerialNumber = true;
        }

        state.diagnosticsData = null;
        state.failureDetails = action.payload.failureDetails || {};
      })

      .addCase(getMachineDetails.rejected, (state, action) => {
        state.errorModal.isOpen = true;
      })

      .addCase(validateCertification.fulfilled, (state, action) => {
        state.maintenanceHistory = !!action.payload.maintenanceHistory ? action.payload.maintenanceHistory : [];
        state.failureDetails = action.payload.failureDetails || {};
        state.certifyModal.isOpen = !!action.payload.passedValidation;
      })

      .addCase(certify.fulfilled, (state, action) => {
        state.maintenanceHistory = !!action.payload.maintenanceHistory ? action.payload.maintenanceHistory : [];
        state.certificateInfos = action.payload.certificateInfos ? action.payload.certificateInfos : [];
        state.certificationSuccessModal.isOpen = action.payload.machineCertified;
        state.certificationFailedModal.isOpen = !action.payload.machineCertified;
        state.certificationFailedModal.failureDetails = action.payload.failureDetails || {};
        state.failureDetails = action.payload.failureDetails || {};
      })

      .addCase(getCertificationReport.fulfilled, (state, action) => {
      })
      
      .addCase(getMachineDiagnosticsData.fulfilled, (state, action) => {
        state.diagnosticsData = action.payload;
      });

  }
});

export const selectDiagnosticsData = (state) => state.searchresults.diagnosticsData;

export const { 
  resetSearchResults, 
  setCertificationHours, 
  setCertificationSuccessModalOpen, 
  setCertificationFailedModalOpen, 
  setReportModalOpen, 
  setErrorModalOpen,
  setCertifyModalOpen
} = searchresultsSlice.actions;

export default searchresultsSlice.reducer;