import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getMachineDiagnosticsData = async (serialNumber) => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.get(`/diagnostics/machine/${serialNumber}`, { headers });

    return data;
};

export default getMachineDiagnosticsData;