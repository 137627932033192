import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import './WorkOrderModal.css';

import { setOpen, addWorkOrder } from './workorder.slice';
import workOrderSchema from "./workorder.schema";

import { Formik } from "formik";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: "100%",
        marginLeft: 0
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const WorkOrderModal = () => {

    const classes = useStyles();

    const dispatch = useDispatch();

    const { isOpen, workOrder } = useSelector(state => state.workorder);
    const { serialNumber } = useSelector(state => state.searchresults);

    const handleClose = () => dispatch(setOpen(false));

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Work Order</Modal.Title>
            </Modal.Header>

            <Formik
                initialValues={workOrder}
                validationSchema={workOrderSchema}
                onSubmit={(values) => dispatch(addWorkOrder({ workOrder: values, serialNumber }))}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                }) => (

                    <form onSubmit={handleSubmit} noValidate autoComplete="off">

                        <Modal.Body>
                            <p className="modal-content-text">This PM Interval is missing data. Please add data in order to certify it.</p>

                            <div className="row">
                                <div className="col-sm-6">

                                    <FormControl className={classes.formControl}>
                                        <TextField 
                                            id="serviceInterval" 
                                            name="serviceInterval" 
                                            label="Interval" 
                                            value={workOrder.serviceInterval} 
                                            disabled 
                                        />
                                    </FormControl>

                                </div>
                                <div className="col-sm-6">

                                    <FormControl className={classes.formControl}>
                                        <TextField 
                                            id="workOrderNumber" 
                                            name="workOrderNumber" 
                                            label="Work Order" 
                                            defaultValue={values.workOrderNumber} 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.workOrderNumber && errors.workOrderNumber}
                                            error={Boolean(touched.workOrderNumber && errors.workOrderNumber)}
                                        />
                                    </FormControl>
                                    
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">

                                    <FormControl className={classes.formControl}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                id="datePerformed"
                                                name="datePerformed"
                                                label="Date Performed"
                                                value={values.datePerformed}
                                                onChange={value => setFieldValue("datePerformed", value)}
                                                onBlur={handleBlur}
                                                helperText={touched.datePerformed && errors.datePerformed}
                                                error={Boolean(touched.datePerformed && errors.datePerformed)}
                                                minDate="01/01/1970"
                                                style={{
                                                    marginTop: "0"
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>

                                </div>
                                <div className="col-sm-6">

                                    <FormControl className={classes.formControl}>
                                    
                                        <TextField 
                                            id="machineHours" 
                                            name="machineHours" 
                                            label="Machine Hours" 
                                            type="number" 
                                            defaultValue={values.machineHours} 
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.machineHours && errors.machineHours}
                                            error={Boolean(touched.machineHours && errors.machineHours)}
                                        />

                                    </FormControl>
                                    
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12">

                                    <FormControl className={classes.formControl}>

                                        <TextField
                                            id="note"
                                            label="Note"
                                            name="note"
                                            multiline
                                            rows={4}
                                            variant="standard"
                                            className={`${classes.input} notes-textarea`}
                                            defaultValue={values.note}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.note && errors.note}
                                            error={Boolean(touched.note && errors.note)}
                                        />
                            
                                    </FormControl>

                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="button" variant="secondary modal-button" onClick={handleClose} disabled={isSubmitting}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary" disabled={isSubmitting}>
                                Add
                            </Button>
                        </Modal.Footer>

                    </form>

                )}

            </Formik>

        </Modal>
    );
};

export default WorkOrderModal;