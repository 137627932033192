import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './ErrorModal.css';

import { setErrorModalOpen } from './searchresults.slice';

const ErrorModal = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { isOpen, missingPMSchema, invalidSerialNumber } = useSelector(state => state.searchresults.errorModal);

    const handleClose = () => {
        dispatch(setErrorModalOpen(false));
        setTimeout(() => {
            history.push(`/`);
        }, 500);
    };

    let errorMessage = 
        (<p className="error-modal-message">Unfortunately we were not able to retrieve records associated with this Serial Number.  Please contact a system administrator for more information.</p>);

    if(!!missingPMSchema){
        errorMessage = 
            (<p className="error-modal-message">Unfortunately we are missing the PM schedule associated with this Serial Number.  Please contact a system administrator for more information.</p>);
    }

    if(!!invalidSerialNumber){
        errorMessage = 
            (<p className="error-modal-message">Please check this is a valid serial number or contact a system administrator for more information.</p>);
    }
    
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                

                <p className="error-modal-header">Something went wrong</p>

                <br />

                {errorMessage}
                
                <br />
                <br />
                <Button variant="primary modal-button" onClick={handleClose}>
                    Ok, Got it
                </Button>

            </Modal.Body>
        </Modal>
    );
};

export default ErrorModal;