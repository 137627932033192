import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './CertifyModal.css';

import { certify, setCertifyModalOpen } from './searchresults.slice';

const CertifyModal = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const { isOpen } = useSelector(state => state.searchresults.certifyModal);
    const { serialNumber, certificationHours } = useSelector(state => state.searchresults);

    const handleClose = () => {
        dispatch(setCertifyModalOpen(false));
    };

    const handleCertify = () => {
        dispatch(setCertifyModalOpen(false));
        dispatch(certify({ serialNumber, certificationHours }));
    };
    
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                

                <p className="certify-modal-header">Certify</p>

                <br />

                <p className="certify-modal-message">This machine is eligible for certification. Do you want to certify at {certificationHours} hours?</p>
                
                <br />
                <br />
                <Button variant="secondary modal-button" onClick={handleClose}>
                    No
                </Button>
                <Button variant="primary modal-button" onClick={handleCertify}>
                    Yes
                </Button>

            </Modal.Body>
        </Modal>
    );
};

export default CertifyModal;