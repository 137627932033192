import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import './CertificationFailedModal.css';

import { setCertificationFailedModalOpen } from './searchresults.slice';

const CertificationFailedModal = (props) => {

    const dispatch = useDispatch();

    // const { certificationHours } = useSelector(state => state.searchresults);
    const { isOpen, failureDetails } = useSelector(state => state.searchresults.certificationFailedModal);

    const handleClose = () => dispatch(setCertificationFailedModalOpen(false));

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                <div className="warning-icon" style={{textAlign: "center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16" style={{height: "48px"}}>
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>&nbsp;
                </div>
                <br />

                <p className="failed-certification-header">This machine can't be certified.</p>

                <div style={{textAlign: "left", margin: "40px 15% 40px 15%"}}>
                    <p style={{color: "#43485C", fontSize: "14px"}}>Failures</p>
                    <ul>
                        <li style={{color: "#43485C"}}>{failureDetails.failureReason}</li>
                    </ul>
                    <hr></hr>
                    {!!failureDetails.warning && (
                        <>
                            <p style={{color: "#43485C", fontSize: "14px"}}>Warnings</p>
                            <ul>
                                <li style={{color: "#43485C"}}>{failureDetails.warning}</li>
                            </ul>
                            <hr></hr>
                        </>
                    )}
                    
                </div>

                {/* <p className="failed-certification-message">For your requested certification at {certificationHours} hours, we are missing data. <br/> To certify this machine please visit the maintenance history and add missing data.</p> */}

                <br />
                <Button variant="primary modal-button" onClick={handleClose}>
                    Ok, Got it
                </Button>

            </Modal.Body>
        </Modal>
    );
};

export default CertificationFailedModal;