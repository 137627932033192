import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getListByIdApi from '../../api/getListById';
import getListItemsApi from '../../api/getListItems';
import getListStatistics from '../../api/getListStatistics';
import runCertificationApi from '../../api/runCertification';
import runSerialNumbersInListApi from '../../api/runSerialNumbersInList';

import { setLoading } from '../loader/loader.slice';

const initialState = {
    list: {},
    listItems: [],
    listStatistics: [],
    filtersSelected: {
        status: [],
        errors: []
    },
    allItemsChecked: false,
    anyItemChecked: false,
    listItemCount: 0,
    itemsPerPage: 25,
    page: 1,
    certifyModal: {
        isOpen: false,
        listItem: {}
    },
    certifyAllModal: {
        isOpen: false
    }
};

export const loadListDetails = createAsyncThunk('listitem/loadListDetails', async ({ listId, page, itemsPerPage }, { dispatch, getState }) => {

    dispatch(setLoading(true));

    const { filtersSelected } = getState().listitems;

    const listResponse = await getListByIdApi(listId);
    const listItemsResponse = await getListItemsApi(listId, page, itemsPerPage, filtersSelected.status, filtersSelected.errors);

    dispatch(setLoading(false));

    return {
        listResponse,
        listItemsResponse
    };
});

export const loadFilterStatistics = createAsyncThunk('listitem/loadFilterStatistics', async ({ listId }, { dispatch, getState }) => {

    dispatch(setLoading(true));

    const listStatisticsResponse = await getListStatistics(listId);

    dispatch(setLoading(false));

    return {
        listStatisticsResponse
    };
});

export const runSerialNumbers = createAsyncThunk('listitem/runSerialNumbers', async ({ listId, serialNumbers }, { dispatch, getState }) => {

    dispatch(setLoading(true));

    await runSerialNumbersInListApi(listId, serialNumbers);

    const state = getState().listitems;
    dispatch(loadListDetails({ listId: state.list.id, page: state.page, itemsPerPage: state.itemsPerPage }));
});

export const runCertification = createAsyncThunk('listitem/runCertification', async ({ listId, serialNumbers }, { dispatch, getState }) => {

    dispatch(setLoading(true));

    await runCertificationApi(listId, serialNumbers);

    const state = getState().listitems;;
    dispatch(loadListDetails({ listId: state.list.id, page: state.page, itemsPerPage: state.itemsPerPage }));
});

export const setPaging = createAsyncThunk('listitem/setPaging', async (params, { dispatch, getState }) => {

    dispatch(loadListDetails(params));

    return params;
});

const listitemslice = createSlice({
  name: 'listitem',
  initialState,
  reducers: {

    setAllItemsChecked(state, action) {
        state.listItems = state.listItems.map(item => {
            return {
                ...item,
                isChecked: action.payload
            }
        });
        state.allItemsChecked = action.payload;
        state.anyItemChecked = action.payload;
    },

    setItemChecked(state, action) {

        const item = state.listItems.filter(x => x.id === action.payload.item.id)[0];
        item.isChecked = action.payload.isChecked;

        if(state.listItems.every(x => !!x.isChecked)){
            state.allItemsChecked = true;
        }
        else {
            state.allItemsChecked = false;
        }

        if(state.listItems.some(x => !!x.isChecked)){
            state.anyItemChecked = true;
        }
        else {
            state.anyItemChecked = false;
        }
    },

    setCertifyModalOpen(state, action) {
        state.certifyModal = action.payload;
    },

    setCertifyAllModalOpen(state, action) {
        state.certifyAllModal = action.payload;
    },

    setFiltersSelected(state, action){
        state.filtersSelected.status = action.payload.status;
        state.filtersSelected.errors = action.payload.errors;
    }

  },

  extraReducers: builder => {
    builder
    
      .addCase(loadListDetails.fulfilled, (state, action) => {
        state.list = {
            ...action.payload.listResponse.list,
            createdOn: new Date(action.payload.listResponse.list.createdOn)
        };
        state.listItems = action.payload.listItemsResponse.items.map(item => {
            return {
                ...item,
                isChecked: false,
                errors: (() => {
                    if(!item.errors){
                        return null;
                    }
                    else if(item.errors.indexOf("missing work order data") > -1){
                        return "Missing work orders."
                    }
                    else if(item.errors.indexOf("minimum hours") > -1){
                        return "Doesn't meet global minimum hours.";
                    }
                    else {
                        return item.errors;
                    }
                })()
            };
        });
        state.listItemCount = action.payload.listItemsResponse.totalCount;
      })

      .addCase(loadFilterStatistics.fulfilled, (state, action) => {
        state.listStatistics = action.payload.listStatisticsResponse.items;
        state.filtersSelected.status = action.payload.listStatisticsResponse.items.filter(x => x.category == "Status").map(x => x.name);
        state.filtersSelected.errors = action.payload.listStatisticsResponse.items.filter(x => x.category == "Errors").map(x => x.name);
      })

      .addCase(setPaging.fulfilled, (state, action) => {
        state.page = action.payload.page;
        state.itemsPerPage = action.payload.itemsPerPage;
      })

      ;
  }
});

export const { setAllItemsChecked, setItemChecked, setCertifyModalOpen, setCertifyAllModalOpen, setFiltersSelected } = listitemslice.actions;

export default listitemslice.reducer;