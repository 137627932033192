import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getLists = async () => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.get('/lists', { headers });

    return data;
};

export default getLists;