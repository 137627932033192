import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const runCertification = async (listId, serialNumbers) => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post(`/lists/${listId}/certify`, { serialNumbers }, { headers });

    return data;
};

export default runCertification;