import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import addWorkOrderApi from '../../api/addWorkOrder';

import { setLoading } from '../loader/loader.slice';
import { validateCertification } from './../search-results/searchresults.slice'

const initialState = {
    isOpen: false,
    workOrder: {
        serviceInterval: 0,
        intervalId: 0,
        workOrderNumber: "",
        datePerformed: new Date(),
        note: "",
        machineHours: 0
    }
};

export const addWorkOrder = createAsyncThunk('workorder/addWorkOrder', async ({ workOrder, serialNumber }, { dispatch, getState }) => {

    dispatch(setLoading(true));

    const response = await addWorkOrderApi(
                                workOrder.intervalId,
                                workOrder.serviceInterval,
                                serialNumber,
                                workOrder.workOrderNumber,
                                workOrder.datePerformed,
                                workOrder.machineHours,
                                workOrder.note
                            );

    const { certificationHours } = getState().searchresults;

    dispatch(validateCertification({ serialNumber, certificationHours: certificationHours }));

    dispatch(setLoading(false));

    return response;
});

const workOrderSlice = createSlice({
  name: 'workorder',
  initialState,
  reducers: {

    openNewWorkOrder(state, action){
        state.workOrder = {...initialState.workOrder};
        state.workOrder.serviceInterval = action.payload.serviceInterval;
        state.workOrder.intervalId = action.payload.intervalId;
        state.isOpen = true;
    },

    setOpen(state, action) {
      state.isOpen = action.payload;
    },

  },
  extraReducers: builder => {
    builder
    
      .addCase(addWorkOrder.fulfilled, (state, action) => {
        state.workOrder = initialState.workOrder;
        state.isOpen = false;
      });

  }
});

export const { setOpen, openNewWorkOrder } = workOrderSlice.actions;

export default workOrderSlice.reducer;