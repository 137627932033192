import * as Yup from "yup";

const workOrderSchema = Yup.object({
    serviceInterval: Yup.number().positive(),
    intervalId: Yup.number().positive(),
    workOrderNumber: Yup.string().required("Required"),
    datePerformed: Yup.date().required("Required").typeError("'Date performed' must be a valid date."),
    note: Yup.string().required("Required"),
    machineHours: Yup.number()
                     .integer()
                     .positive("'Machine hours' should be a positive number.")
                     .required("Required")
});

export default workOrderSchema;