import ccmClient from './ccmClient';
import getCWSService from '../app/getCWSService';

const { getIdToken } = getCWSService();

const selectWorkOrder = async (serialNumber, interval, workOrderNumber) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/maintenance-history/work-orders/select', {
        serialNumber,
        workOrderNumber,
        interval
    }, { headers });

    return data;
};

export default selectWorkOrder;