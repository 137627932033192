import ccmClient from './ccmClient';
import getCWSService from '../app/getCWSService';

const { getIdToken } = getCWSService();

const addNote = async (note, serviceInterval, machineSerialNumber) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/note/add', {
        Value: note,
        ServiceInterval: serviceInterval,
        MachineSerialNumber: machineSerialNumber
    }, { headers });

    return data;
};

export default addNote;