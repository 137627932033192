import { Modal, Button } from 'react-bootstrap';

import './ReportIssueModal.css';

const ReportIssueModal = (props) => {
    
    return (
        <Modal
            show={props.isOpen}
            onHide={props.handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{textAlign: "center"}}>
                

                <p className="report-issue-modal-header">Report an issue</p>

                <br />

                <p className="report-issue-message">To report an issue please contact support at <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>{`${process.env.REACT_APP_SUPPORT_EMAIL}`}</a></p>
                

                <br />
                <br />
                <Button variant="primary modal-button" onClick={props.handleClose}>
                    Ok, Got it
                </Button>

            </Modal.Body>
        </Modal>
    );
};

export default ReportIssueModal;