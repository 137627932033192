import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    '&:before': {
        borderColor: "#FFCC11",
    },
    '&:after': {
        borderColor: "#FFCC11",
    }
},
}));

export default function CertificationHoursDropdown(props) {

  const classes = useStyles();

  const [certificationHours, setCertificationHours] = useState(props.certificationHours);

  const handleBlur = (event) => {
    
    let certificationHoursRounded = Math.floor(event.target.value / 500) * 500;

    var minSMU = props.market === 'GCI' ? 3000 : 1500;

    if(certificationHoursRounded < minSMU){
      certificationHoursRounded = minSMU;
    }

    props.certificationHoursChanged(certificationHoursRounded);
  };

  useEffect(() => {
    setCertificationHours(props.certificationHours);
  }, [props.certificationHours]);

  return (
    <div>
      <FormControl className={classes.formControl}>

        <TextField 
            id="hourSelect" 
            name="hourSelect" 
            label="Hours" 
            value={certificationHours}
            onChange={(e) => setCertificationHours(e.target.value)}
            onBlur={handleBlur}
        />

      </FormControl>
    </div>
  );
}