const validate = (value, validators) => {
    const validations = validators.map(validator => validator(value));

    for(let validation of validations){
        if(!!validation){
            return validation;
        }
    }

    return "";
};

export default validate;