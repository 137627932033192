import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getListItems = async (listId, page, itemsPerPage, statusFilters, errorFilters) => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };

    const params = {
        Page: page,
        ItemsPerPage: itemsPerPage,
        StatusFilters: statusFilters,
        ErrorFilter: errorFilters
    }
    
    const { data } = await ccmClient.post(`/lists/${listId}/items`, params, { headers });

    return data;
};

export default getListItems;