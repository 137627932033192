import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import loaderSlice from '../features/loader/loader.slice';
import noteSlice from '../features/notes/note.slice';
import workOrderSlice from '../features/work-orders/workorder.slice';
import searchresultsSlice from '../features/search-results/searchresults.slice';
import listSlice from '../features/lists/list.slice';
import listItemsSlice from '../features/lists/listitem.slice';
import masterlistSlice from '../features/master-lists/masterlist.slice';
import masterlistitemSlice from '../features/master-lists/masterlistitem.slice';

const store = configureStore({
  reducer: {
    loader: loaderSlice,
    note: noteSlice,
    workorder: workOrderSlice,
    searchresults: searchresultsSlice,
    lists: listSlice,
    listitems: listItemsSlice,
    masterLists: masterlistSlice,
    masterListItems: masterlistitemSlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
});

export default store;