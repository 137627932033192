import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default (props) => {

  const handleDelete = (chipToDelete) => () => {
    props.deleteUser(chipToDelete);
  };

  return (
    <Paper
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        boxShadow: "none"
      }}
      component="ul"
    >
      {props.users?.map((user) => {
        return (
          <ListItem key={user}>
            <Chip
              label={user}
              onDelete={handleDelete(user)}
              style={{ borderRadius: "5px" }}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
};