import ccmClient from './ccmClient'
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const getMasterListStatistics = async (listId) => {
    
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.get(`/master-lists/${listId}/statistics`, { headers });

    return data;
};

export default getMasterListStatistics;