import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { setCertificationHours, getMachineDetails, setReportModalOpen, certify, validateCertification, selectWorkOrder } from './searchresults.slice';
import { openNote, openNewNote } from '../notes/note.slice';
import { openNewWorkOrder } from '../work-orders/workorder.slice';


import NotesModal from '../notes/NotesModal';
import WorkOrderModal from '../work-orders/WorkOrderModal';
import CertificationHoursDropdown from './CertificationHoursDropdown';
import './SearchResultsPage.css'

import CertificationFailedModal from './CertificationFailedModal';
import CertificationSuccessModal from './CertificationSuccessModal';
import ReportModal from './ReportModal';
import { pdf } from '@react-pdf/renderer';
import CertificatePDF from '../export/CertificatePDF';
import ErrorModal from './ErrorModal';
import CertifyModal from './CertifyModal';
import CertificateLanguageSelector from './CertificateLanguageSelector';

import getTranslationSet from './../../api/getTranslationSet';
import DiagnosticsTabs from './DiagnosticsTabs';
import { useHistory } from 'react-router-dom';

const SearchResultsPage = (props) => {

    const { sn } = props.match.params;

    const dispatch = useDispatch();
    const history = useHistory();

    const { 
        serialNumber, 
        maintenanceHistory, 
        certificateInfos,
        certificationHours, 
        market,
        failureDetails
    } = useSelector(state => state.searchresults);

    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        dispatch(getMachineDetails(sn));
    }, [sn, dispatch]);
 
    const handleNotesModalShow = (id, serviceInterval) => !!id ? dispatch(openNote(id)) : dispatch(openNewNote({ serialNumber, serviceInterval }));
    const handleCertificationHoursChanged = (hours) => dispatch(setCertificationHours(hours));
    // const handleValidateCertification = () => dispatch(certify({ serialNumber, certificationHours }));
    const handleValidateCertification = () => dispatch(validateCertification({ serialNumber, certificationHours }));
    const handleAddWorkOrderModalShow = (event, { serviceInterval, intervalId }) => dispatch(openNewWorkOrder({ serviceInterval, intervalId }));

    // Handle certificate rendering
    const handleDownloadCertificate = async (certification, certificateDetails, languageCode) => {

        const translationSetResponse = await getTranslationSet(languageCode);

        const translationSet = {};

        for(let set of translationSetResponse.translationSet){
            translationSet[set.key] = set.value;
        }
        
        const pdfDocumentBlob = await pdf((
            <CertificatePDF
                certification={certification}
                certificateDetails={certificateDetails}
                translationSet={translationSet}
                languageCode={languageCode}
            ></CertificatePDF>
        )).toBlob();

        var fileURL = URL.createObjectURL(pdfDocumentBlob);
        window.open(fileURL);
    };

    // Render Work Orders
    const workOrderList = maintenanceHistory.map(x => {

        // If Work order missing return empty row
        if(!!x.isMissing){
            return (
                <tr key={x.serviceInterval} className="missing-work-order-row">
                    <td className="missing-work-order-row-interval">
                        {x.serviceInterval} ({x.description})
                    </td>
                    <td colSpan="6" className="missing-work-order-row-description">
                        <span className="warning-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                            </svg>&nbsp;
                        </span>
                        Missing work order data.&nbsp;
                        <a className="link" onClick={e => handleAddWorkOrderModalShow(e, x)}>Add data</a>
                    </td>
                </tr>
            );
        };

        // Render Notes
        let noteLink = (
            <a className="link" onClick={() => handleNotesModalShow(null, x.serviceInterval)}>+ Add Note</a>
        );

        if(!!x.note){
            noteLink = (
                <a className="link" onClick={() => handleNotesModalShow(x.noteId, x.serviceInterval)}>{x.note.length > 100 ? `${x.note.substring(0, 100)}...` : x.note}</a>
            );
        }

        // Render Work Order Source
        let dealerSupplied = (<></>);

        if(!!x.dealerSupplied){
            dealerSupplied = (
                <span className="badge badge-primary">DEALER SUPPLIED</span>
            );
        }

        let outOfRange = (<></>);

        if(!!x.outOfRange){
            outOfRange = (
                <div className="warning-pm-background" style={ { backgroundColor: "#FF8000", width: "160px" } }>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill warning-outofrange-icon" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg></span>
                    <span className="warning-pm-text">Accuracy Warning</span>
                </div>
            );
        }

        if(!x.cvaActive){
            outOfRange = (
                <div className="warning-pm-background" style={ { backgroundColor: "#ED1C24" } }>
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill warning-outofrange-icon" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg></span>
                    <span className="warning-pm-text">Missing CVA</span>
                </div>
            );
        }

        const workOrderNumbers = x.workOrderItems.map(x => <React.Fragment key={x.number}>
            {x.isSelected ? <><CheckIcon></CheckIcon></> : <></>}
            <span style={{ marginLeft: x.isSelected ? "0px" : "24px", fontWeight: x.isSelected ? "bold" : "normal" }}>{x.number}</span>{!x.isSelected ? <><ArrowForwardIcon style={{fontSize: "18px"}}></ArrowForwardIcon><a href="#" onClick={(e) => { e.preventDefault(); dispatch(selectWorkOrder({ workOrderNumber: x.number, interval: x.interval, machineSerialNumber: serialNumber }))}}> Use this work order</a></> : <></>}
            <br></br>
        </React.Fragment>);
        const smcsCodes = x.workOrderItems.map(x => <React.Fragment key={x.smcsCode}>
            {x.isSelected ? <><CheckIcon></CheckIcon></> : <></>}
            <span style={{ marginLeft: x.isSelected ? "0px" : "24px", fontWeight: x.isSelected ? "bold" : "normal" }}>{x.smcsCode}</span><br></br>
        </React.Fragment>);
        const smus = x.workOrderItems.map(x => <React.Fragment key={x.smu}>
            {x.isSelected ? <><CheckIcon></CheckIcon></> : <></>}
            <span style={{ marginLeft: x.isSelected ? "0px" : "24px", fontWeight: x.isSelected ? "bold" : "normal" }}>{x.smu}</span>
            <br></br>
        </React.Fragment>);
        const datesPerformed = x.workOrderItems.map(x => <React.Fragment key={x.number}>
            {x.isSelected ? <><CheckIcon></CheckIcon></> : <></>}
            <span style={{ marginLeft: x.isSelected ? "0px" : "24px", fontWeight: x.isSelected ? "bold" : "normal" }}>{!!x.date ? format(new Date(x.date), 'MM/dd/yyyy') : ''}</span><br></br>
        </React.Fragment>);

        return (
            <tr key={x.serviceInterval}>
                <td>{x.serviceInterval} ({x.description})</td>
                <td>{outOfRange}</td>
                <td>{smus}</td>
                <td>{workOrderNumbers} {dealerSupplied}</td>
                <td>{smcsCodes}</td>
                <td>{datesPerformed}</td>
                <td>{noteLink}</td>
            </tr>
        );
    });

    let certificationSection = (null);

    if(!!certificateInfos && certificateInfos.length > 0){
        certificationSection = (
            <>
                <hr className="table-divider"></hr>

                <h4 className="section-header">Certification</h4>

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="w-30">Machine Hours</th>
                            <th scope="col" className="w-30">Date Certified</th>
                            <th scope="col" className="w-30">Servicing Dealer</th>
                            <th scope="col" className="w-10"></th>
                        </tr>
                    </thead>
                    <tbody>

                        {certificateInfos.map(certification => {

                            const createdOnDate = new Date(certification.certificate.createdOn);

                            return (
                                <tr key={certification.certificate.id}>
                                    <td>{certification.certificate.hours}</td>
                                    <td>{format(createdOnDate, 'MM/dd/yyyy')}</td>
                                    <td>{certification.certificate.dealer}</td>
                                    <td className="download-certificate-container">
                                        <CertificateLanguageSelector
                                            handleDownloadCertificate={(lang) => handleDownloadCertificate(certification.certificate, certification.details, lang)}
                                        ></CertificateLanguageSelector>
                                    </td>
                                </tr>
                            );
                        })}
                        
                    </tbody>
                </table>
            </>
        );
    }

    let failureReason = (<></>);

    if(!!failureDetails?.failureReason){

        if(failureDetails?.failureReason?.indexOf("work order") > -1){
            failureReason = (
            <div className="warning-pm-background" style={ { backgroundColor: "#ED1C24", marginLeft: "10px", width: "170px" } }>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill warning-outofrange-icon" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg></span>
                <span className="warning-pm-text">Missing work orders</span>
            </div>
            );
        }
        else if(failureDetails?.failureReason?.indexOf("CVA") > -1){
            failureReason = (
            <div className="warning-pm-background" style={ { backgroundColor: "#ED1C24", marginLeft: "10px", width: "170px" } }>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill warning-outofrange-icon" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg></span>
                <span className="warning-pm-text">Missing CVA</span>
            </div>
            );
        }
        else if(failureDetails?.failureReason?.indexOf("old") > -1){
            failureReason = (
            <div className="warning-pm-background" style={ { backgroundColor: "#ED1C24", marginLeft: "10px", width: "170px" } }>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill warning-outofrange-icon" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path></svg></span>
                <span className="warning-pm-text">Machine too old.</span>
            </div>
            );
        }
    }

    return (
        <div className="container container-search-results">
            <div className="row">
                <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mt-3 mb-3">
                                            <svg style={{ width: "30px", height: "40px", marginRight: "15px", paddingBottom: "4px", cursor: "pointer" }} onClick={(e) => history.goBack()} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left-long" className="svg-inline--fa fa-arrow-left-long" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M9.375 233.4l128-128c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H480c17.69 0 32 14.31 32 32s-14.31 32-32 32H109.3l73.38 73.38c12.5 12.5 12.5 32.75 0 45.25c-12.49 12.49-32.74 12.51-45.25 0l-128-128C-3.125 266.1-3.125 245.9 9.375 233.4z"></path></svg>
                                            Cat<span className="copyright">®</span> Certified Maintained Machine History for {sn}

                                            {failureReason}
                                            
                                        </div>
                                    </div>
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-1">
                                        <CertificationHoursDropdown 
                                            certificationHours={certificationHours}
                                            certificationHoursChanged={handleCertificationHoursChanged}
                                            market={market}
                                        ></CertificationHoursDropdown>
                                    </div>
                                    <div className="col-sm-1">
                                        <button className="btn btn-primary mt-2" onClick={handleValidateCertification} style={{ width: "160px" }}>Check Certification</button>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="card-body">
                                <DiagnosticsTabs serialNumber={serialNumber}>
                                    <h4 className="section-header">Maintenance History</h4>
                                    <a className="link download-maintenance-history" onClick={() => dispatch(setReportModalOpen(true))}>Download</a>

                                    <table className="table table-maintenance-history">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-20">PM Interval</th>
                                                <th scope="col" className="w-15">Warnings</th>
                                                <th scope="col" className="w-10">Machine Hours</th>
                                                <th scope="col" className="w-20">Work Order #</th>
                                                <th scope="col" className="w-10">SMCS code</th>
                                                <th scope="col" className="w-10">Date Performed</th>
                                                <th scope="col" className="w-15">Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {workOrderList}
                                        </tbody>
                                    </table>
                                    
                                    {certificationSection}
                                </DiagnosticsTabs>
                            </div>
                        </div>
                </div>
            </div>

            <NotesModal></NotesModal>
            <WorkOrderModal></WorkOrderModal>
            <CertificationFailedModal></CertificationFailedModal>
            <CertificationSuccessModal></CertificationSuccessModal>
            <ReportModal></ReportModal>
            <ErrorModal></ErrorModal>
            <CertifyModal></CertifyModal>
        </div>
    );
};

export default SearchResultsPage;