import ccmClient from './ccmClient';
import getCWSService from './../app/getCWSService';

const { getIdToken } = getCWSService();

const validateCertificationMaxSMU = async (serialNumber) => {
    const headers = {
        'Authorization': `Bearer ${await getIdToken()}`
    };
    
    const { data } = await ccmClient.post('/certification/validatemaxsmu', {
        machineSerialNumber: serialNumber,
        certificationHours: 0
    }, { headers });

    return data;
};

export default validateCertificationMaxSMU;