import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import addListApi from '../../api/addList';

import getLists from '../../api/getLists';
import runListApi from '../../api/runList';
import archiveListApi from '../../api/archiveList';
import updateListApi from '../../api/updateList';

import { setLoading } from '../loader/loader.slice';

const initialState = {
    lists: [],
    isAddListModalOpen: false,
    list: {
      id: 0,
      name: '',
      users: ''
    }
};

export const loadLists = createAsyncThunk('lists/loadlists', async (param, { dispatch, getState }) => {

    dispatch(setLoading(true));

    const listsResponse = await getLists();

    dispatch(setLoading(false));

    return listsResponse;
});

export const loadlistsquietly = createAsyncThunk('lists/loadlistsquietly', async (param, { dispatch, getState }) => {

  const listsResponse = await getLists();

  return listsResponse;
});

export const addList = createAsyncThunk('lists/addList', async ({listName, users, base64File}, { dispatch, getState }) => {

  dispatch(setLoading(true));

  const response = await addListApi(listName, users, base64File);
  await runListApi(response.listId);

  dispatch(setLoading(false));
  dispatch(loadLists());

  return response;
});

export const updateList = createAsyncThunk('lists/updateList', async ({ listId, listName, users }, { dispatch, getState }) => {

  dispatch(setLoading(true));

  const response = await updateListApi(listId, listName, users);

  dispatch(setLoading(false));
  dispatch(loadLists());

  return response;
});

export const runList = createAsyncThunk('lists/runList', async ({ listId }, { dispatch, getState }) => {

  dispatch(setLoading(true));
  await runListApi(listId);
  dispatch(loadLists());
});

export const archiveList = createAsyncThunk('lists/archiveList', async ({ listId }, { dispatch, getState }) => {

  dispatch(setLoading(true));
  await archiveListApi(listId);
  dispatch(loadLists());
});

const listslice = createSlice({
  name: 'lists',
  initialState,
  reducers: {

    addNewList(state, action){
        state.list = {...initialState.list};
        state.isAddListModalOpen = true;
    },

    setAddListModalOpen(state, action) {
      state.isAddListModalOpen = action.payload.isOpen;
      state.list = !!action.payload.list ? action.payload.list : initialState.list;
    },

  },

  extraReducers: builder => {
    builder
    
      .addCase(loadLists.fulfilled, (state, action) => {
        state.lists = action.payload.lists;
      })

      .addCase(loadlistsquietly.fulfilled, (state, action) => {
        state.lists = action.payload.lists;
      })

      .addCase(addList.fulfilled, (state, action) => {
        state.isAddListModalOpen = false;
        state.list = initialState.list;
      })

      .addCase(updateList.fulfilled, (state, action) => {
        state.isAddListModalOpen = false;
        state.list = initialState.list;
      })
      
      ;
  }
});

export const { addNewList, setAddListModalOpen } = listslice.actions;

export default listslice.reducer;