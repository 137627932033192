import randomstring from "randomstring";
import { encode as base64encode } from "base64-arraybuffer";
import jwt_decode from "jwt-decode";

import fetchToken from "../app/fetchToken";
import { useEffect, useState } from "react";

const currentToken = JSON.parse(localStorage.getItem('token'));

const generateCodeChallenge = async (codeVerifier) => {

    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest("SHA-256", data);
    const base64Digest = base64encode(digest);

    return base64Digest
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
};

const Authorization = (props) => {

    if(process.env.REACT_APP_ENVIRONMENT_NAME === "local"){
        return (
            props.children
        );
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [token, setIdToken] = useState(currentToken);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {

        (async () => {

            const authenticate = async () => {
                const code_verifier = randomstring.generate(128);            
                const code_challenge = await generateCodeChallenge(code_verifier);

                localStorage.setItem("state", JSON.stringify({
                    code_verifier,
                    code_challenge
                }));

                const authUrl = `${process.env.REACT_APP_CWS_URL}/as/authorization.oauth2?client_id=${process.env.REACT_APP_CWS_CLIENTID}&pfidpadapterid=${process.env.REACT_APP_CWS_ADAPTER}&response_type=code&grant_type=authorization_code&scope=openid+profile&state=${code_verifier}&code_challenge=${code_challenge}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_CWS_REDIRECT_URL)}`;
                window.location = authUrl;
            };

            const fetchTokenUsingCode = async (code, state) => {
                const { code_verifier, code_challenge } = JSON.parse(localStorage.getItem("state"));

                if(code_verifier === state){
                    const tokenResponse = await fetchToken(code, code_challenge);
                    
                    if(tokenResponse.status === 200){
                        localStorage.setItem('token', JSON.stringify(tokenResponse.data));
                        setIdToken(tokenResponse.data);
                        window.history.pushState(null, "", window.location.href.split("?")[0]);
                    }
                }
                else {
                    console.log("Failed request forgery check.");
                }
            };

            if(!token){

                const queryString = window.location.search;

                const urlParams = new URLSearchParams(queryString);
                const code = urlParams.get("code");
                const state = urlParams.get("state");

                if(!code){
                    await authenticate();
                }
                else{
                    await fetchTokenUsingCode(code, state);
                }
            }
            else {
                const decodedToken = jwt_decode(token.id_token);
                const now = Date.now().valueOf() / 1000;

                if (typeof decodedToken.exp !== 'undefined' && decodedToken.exp < now) {
                    localStorage.removeItem('token');
                    await authenticate();
                }
            }
        })();

    }, [token]);

    if(!token){
        return (<div></div>);
    }

    return (
        props.children
    );
};

export default Authorization;